import React, { useEffect, useState } from 'react';
import BasketList from 'components/BasketList';
import BasketTotal from 'components/BasketTotal';
import ActionsToolbar from 'components/ActionsToolbar';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { resetBasket } from 'store/basketSlice';
import { toggleVirtualKeyboard, toggleZipVirtualKeyboard } from 'store/appSlice';

const DefaultEntry: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const raw_items = useAppSelector((state) => state.basket.raw_items);
    const main_event = useAppSelector((state) => state.application.current_main_event);
    const [zipAsked, setZipAsked] = useState(false);

    useEffect(() => {
        if (main_event) setZipAsked(main_event.zip_asked);
    }, [main_event]);

    const onCancelAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(resetBasket());
    };

    const onContinueAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (zipAsked) {
            dispatch(toggleZipVirtualKeyboard());
        } else {
            dispatch(toggleVirtualKeyboard());
        }
    };

    return (
        <div className="container-fluid d-flex flex-column justify-content-between default-entry">
            <BasketList />
            <div>
                <BasketTotal />
                <ActionsToolbar
                    continue_text={t('msc.cash_in')}
                    continue_disabled={raw_items.length === 0}
                    cancel_action={onCancelAction}
                    continue_action={onContinueAction}
                />
            </div>
        </div>
    );
};

export default DefaultEntry;
