import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Timestamp from 'components/History/Timestamp';
import IndexedDBContext from 'utils/IndexedDB';
import { shortFormatCurrency, shortenLabel } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleHistoryList, toggleHistoryPrint } from 'store/appSlice';
import { BasketState } from 'store/basketSlice';

const HistoryList: React.FC = () => {
    const dispatch = useAppDispatch();
    const storage = useContext(IndexedDBContext);
    const history_list_open = useAppSelector((state) => state.application.history_list_open);
    const history_print_open = useAppSelector((state) => state.application.history_print_open);
    const [orders, setOrders] = useState<BasketState[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (history_list_open) {
            // On open modal get orders
            storage.getAllOrders((data: BasketState[]) => {
                setOrders(data);
            });
        } else {
            // Reset data on close modal
            setOrders([]);
        }
    }, [history_list_open]);

    const closeHistoryList = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Only toggle keyboard if it's already open
        if (history_list_open) dispatch(toggleHistoryList());
    };

    const openHistoryPrint = (order: BasketState) => (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (history_list_open) dispatch(toggleHistoryList());
        if (!history_print_open) dispatch(toggleHistoryPrint(order));
    };

    if (history_list_open) {
        return (
            <>
                <div className="overlay-dark" onClick={closeHistoryList} />
                <div className="historylist bg-gray">
                    {orders && orders.length > 0 ? (
                        [...orders].slice(0, 3).map((order, index) => {
                            return (
                                <div className="d-flex flex-column" key={index}>
                                    <div className="order">
                                        <div className="d-flex">
                                            <ul>
                                                {order.items.slice(0, 2).map((item, index) => (
                                                    <li key={index}>
                                                        {item.quantity} x {shortenLabel(item.label)}
                                                    </li>
                                                ))}
                                                {order.items.length > 2 && <li style={{ paddingLeft: '23px' }}>...</li>}

                                                <li>Total {shortFormatCurrency(order.total, 'CHF')}</li>
                                            </ul>
                                            <div className="timestamp">
                                                {order.date && <Timestamp timestamp={parseInt(order.date, 10)} />}
                                            </div>
                                        </div>
                                        <div className="actions">
                                            <button
                                                className="btn btn-success btn-lg"
                                                onClick={openHistoryPrint(order)}
                                            >
                                                {t('msc.details')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className="text-center">{t('notifications.no_orders')}</p>
                    )}
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default HistoryList;
