import React from 'react';
import { LoginProps } from 'types/settings';

const Login: React.FC<LoginProps> = ({
    username,
    password,
    onLoginInputChange,
    onLoginClick,
    isLoginInputChanged,
    loginStatus,
}) => {
    return (
        <div className="mr-2 ml-2" style={{ flex: 1 }}>
            <div className="section-title">Login</div>
            <div className="text-center text-light">Status : {loginStatus()}</div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control form-control-lg"
                    name="username"
                    placeholder="Nom d'utilisateur"
                    value={username}
                    onChange={onLoginInputChange}
                />
            </div>
            <div className="form-group">
                <input
                    type="password"
                    className="form-control form-control-lg"
                    name="password"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={onLoginInputChange}
                />
            </div>
            <div className="form-group">
                <button
                    className="btn btn-success btn-block btn-lg"
                    disabled={!isLoginInputChanged}
                    onClick={onLoginClick}
                >
                    Connexion
                </button>
            </div>
        </div>
    );
};

export default Login;
