import React from 'react';
import { useTranslation } from 'react-i18next';
import Keyboard from 'components/Keyboard';
import ActionsToolbar from 'components/ActionsToolbar';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleVirtualKeyboard, toggleZipVirtualKeyboard } from 'store/appSlice';
import { updateZip } from 'store/basketSlice';

const ZipVirtualKeyboard: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const zip = useAppSelector((state) => state.basket.zip);
    const zip_virtual_keyboard_open = useAppSelector((state) => state.application.zip_virtual_keyboard_open);

    const toggleKeyboard = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Only toggle keyboard if it's already open
        if (zip_virtual_keyboard_open) dispatch(toggleZipVirtualKeyboard());
    };

    const clearInput = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(updateZip(null));
    };

    const setInput = (value: string) => {
        dispatch(updateZip(value));
    };

    const onCancelAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(toggleZipVirtualKeyboard());
    };

    const onContinueAction = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(toggleZipVirtualKeyboard());
        dispatch(toggleVirtualKeyboard());
    };

    if (zip_virtual_keyboard_open) {
        return (
            <>
                <div className="overlay-dark" onClick={toggleKeyboard} />
                <div className="keyboard-wrapper-payment bg-gray">
                    <div className="keyboard-input">
                        <div>{t('order.zip')} :</div>
                        <div className="d-flex">
                            <div className="delete-icon" onClick={clearInput}>
                                <i className="far fa-times-circle" />
                            </div>
                            <div>{zip}</div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <Keyboard
                            updateInput={setInput}
                            input={zip || ''}
                            decimal={false}
                            disabled={zip ? zip.length === 4 : false}
                        />
                    </div>
                    <ActionsToolbar
                        size={64}
                        radius={false}
                        continue_text={t('msc.cash_in')}
                        cancel_action={onCancelAction}
                        continue_action={onContinueAction}
                        continue_disabled={false}
                    />
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default ZipVirtualKeyboard;
