import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/helper';
import { BasketState } from 'store/basketSlice';
import { createNewSession } from 'store/apiSlice';
import BrowserStorage from 'utils/BrowserStorage';
import { MainEvent } from 'types/event';
import { AppState, node_environment, guests_members } from 'types/app';

const browserStorage = new BrowserStorage();

export const initialState: AppState = {
    environment: process.env.NODE_ENV === NODE_ENV_DEV ? NODE_ENV_DEV : NODE_ENV_PROD,
    discount_virtual_keyboard_open: false,
    payment_virtual_keyboard_open: false,
    zip_virtual_keyboard_open: false,
    manual_entry: false,
    history_list_open: false,
    history_print_open: false,
    history_cancel_open: false,
    user_actions_open: false,
    resume_open: false,
    app_conf_open: false,
    app_login_open: false,
    app_is_locked: true,
    admin_unlocked: browserStorage.getAdminUnlocked(),
    app_admin_mode: false,
    app_choose_event_open: false,
    guests_and_members_open: false,
    guests_and_members_validate_open: false,
    locale: navigator.language.substring(0, 2) === 'de' ? navigator.language.substring(0, 2) : 'fr',
    selected_history_order: undefined,
    pos_sellers_mode: browserStorage.getPOSSellerMode(),
    current_main_event: browserStorage.getCurrentMainEvent(),
    account: browserStorage.getAccount(),
    pos_seller: browserStorage.getPOSSeller(),
    session_uuid: browserStorage.getSessionUUID(),
};

// Asynchronous action creator
export const triggerCreateNewSession = (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    if (state.application.session_uuid === null) {
        try {
            const action = await dispatch(createNewSession());
            if (createNewSession.fulfilled.match(action) && action.payload.uuid) {
                dispatch(updateSessionUUID(action.payload.uuid));
            } else {
                console.error('Invalid session UUID in response:', action.payload);
            }
        } catch (error) {
            console.error('Error creating new session:', error);
        }
    }
};

export const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        updateNodeEnvironment: (state, action: PayloadAction<node_environment>) => {
            state.environment = action.payload;
        },
        toggleDiscountVirtualKeyboard: (state) => {
            state.discount_virtual_keyboard_open = !state.discount_virtual_keyboard_open;
        },
        toggleVirtualKeyboard: (state) => {
            state.payment_virtual_keyboard_open = !state.payment_virtual_keyboard_open;
        },
        toggleZipVirtualKeyboard: (state) => {
            state.zip_virtual_keyboard_open = !state.zip_virtual_keyboard_open;
        },
        toggleManualEntry: (state) => {
            state.manual_entry = !state.manual_entry;
        },
        toggleHistoryList: (state) => {
            state.history_list_open = !state.history_list_open;
        },
        toggleHistoryPrint: (state, action: PayloadAction<BasketState | undefined>) => {
            state.history_print_open = !state.history_print_open;
            if (action.payload) {
                state.selected_history_order = action.payload;
            } else {
                state.selected_history_order = undefined;
            }
        },
        closeUserActions: (state) => {
            state.user_actions_open = false;
        },
        openUserActions: (state) => {
            state.user_actions_open = true;
        },
        toggleResume: (state) => {
            state.resume_open = !state.resume_open;
        },
        toggleAppConf: (state) => {
            state.app_conf_open = !state.app_conf_open;
        },
        openAppLogin: (state) => {
            state.app_login_open = true;
        },
        closeAppLogin: (state) => {
            state.app_login_open = false;
        },
        toggleChooseEvent: (state) => {
            state.app_choose_event_open = !state.app_choose_event_open;
        },
        toggleGuestsAndMembers: (state, action: PayloadAction<boolean | guests_members>) => {
            if (!state.guests_and_members_open) {
                state.guests_and_members_open = action.payload;
            } else {
                state.guests_and_members_open = false;
            }
        },
        toggleGuestAndMembersValidate: (state) => {
            state.guests_and_members_validate_open = !state.guests_and_members_validate_open;
        },
        setLocked: (state, action) => {
            state.app_is_locked = action.payload;
        },
        setAccount: (state, action) => {
            state.account = action.payload;
        },
        setAdminMode: (state, action) => {
            state.app_admin_mode = action.payload;
        },
        setPOSMode: (state, action) => {
            state.pos_sellers_mode = action.payload;
        },
        setPOSSeller: (state, action) => {
            state.pos_seller = action.payload;
        },
        setAdminUnlocked: (state) => {
            state.admin_unlocked = true;
            browserStorage.setAdminUnlocked(true);
        },
        updateCurrentMainEvent: (state, action: PayloadAction<MainEvent>) => {
            state.current_main_event = action.payload;
            browserStorage.setCurrentMainEvent(action.payload);
            // (triggers a new session creation)
        },
        updateSessionUUID: (state, action: PayloadAction<string>) => {
            browserStorage.setSessionUUID(action.payload);
            state.session_uuid = action.payload;
        },
        handleAppLogout: (state) => {
            state.discount_virtual_keyboard_open = false;
            state.payment_virtual_keyboard_open = false;
            state.zip_virtual_keyboard_open = false;
            state.manual_entry = false;
            state.history_list_open = false;
            state.history_print_open = false;
            state.history_cancel_open = false;
            state.user_actions_open = false;
            state.resume_open = false;
            state.app_conf_open = false;
            state.app_login_open = false;
            state.app_is_locked = true;
            state.admin_unlocked = true;
            state.app_admin_mode = false;
            state.app_choose_event_open = false;
            state.guests_and_members_open = false;
            state.guests_and_members_validate_open = false;
            state.selected_history_order = undefined;
            state.current_main_event = null;
            state.pos_seller = null;
            state.session_uuid = null;
        },
    },
});

export const {
    updateNodeEnvironment,
    toggleDiscountVirtualKeyboard,
    toggleVirtualKeyboard,
    toggleZipVirtualKeyboard,
    toggleManualEntry,
    toggleHistoryList,
    toggleHistoryPrint,
    closeUserActions,
    openUserActions,
    toggleResume,
    toggleAppConf,
    openAppLogin,
    closeAppLogin,
    toggleChooseEvent,
    toggleGuestsAndMembers,
    toggleGuestAndMembersValidate,
    setLocked,
    setAccount,
    setAdminMode,
    setPOSMode,
    setPOSSeller,
    setAdminUnlocked,
    updateCurrentMainEvent,
    updateSessionUUID,
    handleAppLogout,
} = appSlice.actions;

export default appSlice.reducer;
