import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import BrowserStorage from 'utils/BrowserStorage';
import { BasketItem } from 'types/basket';
import moment from 'moment';
import { TicketPrice } from 'types/event';
import { PosSeller } from 'types/account';

const browserStorage = new BrowserStorage();
const pos_seller = browserStorage.getPOSSeller();

export type BasketState = {
    readonly id?: number | null;
    readonly seller_name: string;
    readonly seller_number: string;
    readonly raw_items: TicketPrice[];
    readonly items: BasketItem[];
    readonly total: number;
    readonly sub_total: number;
    readonly discount: number;
    readonly discount_percent: number;
    readonly taxes: number;
    readonly tax_rate: number;
    readonly cash: number;
    readonly change: number;
    readonly card: boolean;
    readonly manual_ticket_price: number;
    readonly date: string | null; // timestamp
    readonly zip: string | null;
};

export const initialState: BasketState = {
    seller_name: pos_seller?.name || 'Caisse',
    seller_number: pos_seller?.id.toString() || '1',
    raw_items: [],
    items: [],
    total: 0,
    sub_total: 0,
    discount: 0,
    discount_percent: 0,
    taxes: 0,
    tax_rate: 0,
    cash: 0,
    change: 0,
    card: false,
    manual_ticket_price: 0,
    date: null,
    zip: null,
};

const computeBasket = (state: Draft<BasketState>): Draft<BasketState> => {
    const locale = navigator.language.substring(0, 2) === 'de' ? navigator.language.substring(0, 2) : 'fr';

    let items_new: BasketItem[] = [];
    state.raw_items.forEach((item, index) => {
        const idx = items_new.findIndex((element) => element.id === item.id && element.price_unit === item.price_cents);

        if (idx !== -1) {
            items_new[idx].amount = items_new[idx].amount + item.price_cents;
            items_new[idx].quantity = items_new[idx].quantity + 1;
        } else {
            items_new.push({
                id: item.id,
                sku: item.shop_sku,
                amount: item.price_cents,
                price_unit: item.price_cents,
                quantity: 1,
                label: item.labels.label[locale] ? item.labels.label[locale] : item.label,
                org_item: item,
            });
        }
    });

    state.items = items_new;

    // sub-total
    let sub_total = 0;
    state.raw_items.forEach((item) => {
        sub_total += item.price_cents;
    });
    state.sub_total = sub_total;

    // Add discount
    if (state.discount_percent !== 0) {
        state.discount = (state.sub_total / 100) * state.discount_percent;
    }

    state.total = state.sub_total - state.discount;

    state.taxes = Math.round(state.total * state.tax_rate);

    // Calculate change
    if (state.cash !== 0) {
        state.change = state.cash - state.total;
    }

    return state;
};

export const basketSlice = createSlice({
    name: 'basket',
    initialState: initialState,
    reducers: {
        updateSeller: (state, action: PayloadAction<PosSeller>) => {
            state.seller_name = action.payload.name;
            state.seller_number = action.payload.id.toString();
            return computeBasket(state);
        },
        updateTaxeRate: (state, action: PayloadAction<number>) => {
            state.tax_rate = action.payload;
        },
        addTicketToBasket: (state, action: PayloadAction<TicketPrice>) => {
            state.raw_items.push(action.payload);
            return computeBasket(state);
        },
        removeTicketToBasket: (state, action: PayloadAction<TicketPrice>) => {
            const idx = state.raw_items.findIndex((x) => x.id === action.payload.id);
            state.raw_items.splice(idx, 1);
            return computeBasket(state);
        },
        removeTicketsToBasket: (state, action: PayloadAction<BasketItem>) => {
            //const tickets = state.raw_items.filter((el) => action.payload.id !== el.id && action.payload.unique_price !== el.price_cents);
            state.raw_items = state.raw_items.filter((el) => action.payload.id !== el.id);
            return computeBasket(state);
        },
        updateBasketAfterApiResponse: (state, action: PayloadAction<any>) => {
            // set basket id
            const response = action.payload;
            state.id = response.id;

            // set tickets codes
            if (!response.items) {
                return;
            }
            for (const item of response.items) {
                if (!item.tickets) continue;
                for (const ticket of item.tickets) {
                    if (!ticket.code) continue;
                    const stateItem = state.items.find((x) => x.sku === item.sku);
                    if (!stateItem) continue;
                    stateItem.org_item.code = ticket.code;
                }
            }
        },
        resetBasket: (state) => {
            state.id = null;
            state.raw_items = [];
            state.items = [];
            state.total = 0;
            state.sub_total = 0;
            state.discount = 0;
            state.discount_percent = 0;
            state.taxes = 0;
            state.tax_rate = 0;
            state.cash = 0;
            state.change = 0;
            state.card = false;
            state.manual_ticket_price = 0;
            state.date = null;
            state.zip = null;
        },
        updateDiscountPercent: (state, action: PayloadAction<number>) => {
            state.discount_percent = action.payload;
            return computeBasket(state);
        },
        updateDiscountResult: (state, action: PayloadAction<number>) => {
            state.discount = action.payload;
            state.discount_percent = 0;
            return computeBasket(state);
        },
        updateCash: (state, action: PayloadAction<number>) => {
            state.cash = action.payload;
            return computeBasket(state);
        },
        updateCreditCard: (state, action: PayloadAction<boolean>) => {
            state.card = action.payload;
        },
        updateManualTicketPrice: (state, action: PayloadAction<number>) => {
            state.manual_ticket_price = action.payload;
            return computeBasket(state);
        },
        updateDate: (state) => {
            state.date = moment().format('X');
        },
        updateZip: (state, action: PayloadAction<string | null>) => {
            state.zip = action.payload;
        },
    },
});

export const {
    // updateSellerName,
    // updateSellerNumber,
    updateSeller,
    updateTaxeRate,
    addTicketToBasket,
    removeTicketToBasket,
    removeTicketsToBasket,
    resetBasket,
    updateDiscountPercent,
    updateDiscountResult,
    updateCash,
    updateCreditCard,
    updateManualTicketPrice,
    updateDate,
    updateZip,
    updateBasketAfterApiResponse,
} = basketSlice.actions;

export default basketSlice.reducer;
