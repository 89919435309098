import moment from 'moment';
import { TFunction } from 'i18next';
import BrowserStorage from 'utils/BrowserStorage';
import { Order } from 'types/order';
import { Event } from 'types/event';

require('moment/locale/fr');
require('moment/locale/de');

const CONNECTION_MESSGE_OK = 'OK';
const CONNECTION_MESSAGE_OK_SLL = 'SSL_CONNECT_OK';
const DEVICE_ID = 'local_printer';
const OPTIONS = {
    crypto: false,
    buffer: false,
};
const MAX_CONNECTION_RETRY = 5;
// The column available for ticket with a width of 80
export const MAX_CHARACTERS_PER_LINE = 48;

interface LastTicketPrinted {
    readonly basket: Order;
    readonly event: Event;
}

/**
 * Epson SDK Wrapper
 */
class Printer {
    protected readonly translator: TFunction;
    protected readonly storage: BrowserStorage;
    protected readonly ePosDev: any;
    private ipAddress: string | null;
    private port: string | null;
    private retry: number;
    protected printer: any;
    private status: string | undefined;
    private updateErrorOnReduxStore: any;
    private updateIsConnectedOnReduxStore: any;
    protected updateIsPrintingOnReduxStore: any;
    private reconnectIntervalID: number | null;
    protected lastTicketsPrinted: LastTicketPrinted[] = [];
    protected lock: boolean;
    protected qty: number;
    protected locale: string;

    constructor(t: TFunction) {
        this.translator = t;
        this.storage = new BrowserStorage();
        this.ePosDev = window.ePosDev;
        this.ePosDev.onreconnecting = this.onReconnecting;
        this.ePosDev.onreconnect = this.onReconnect;
        this.ePosDev.ondisconnect = this.onDisconnect;
        this.ipAddress = null;
        this.port = null;
        this.retry = 0;
        this.printer = null;
        this.status = undefined;
        this.updateErrorOnReduxStore = null;
        this.updateIsConnectedOnReduxStore = null;
        this.updateIsPrintingOnReduxStore = null;
        this.reconnectIntervalID = null;
        this.lock = false;
        this.qty = 0;
        this.locale = 'fr';
    }

    setLocale = (locale: string): void => {
        this.locale = locale;
        moment.locale(locale);
    };

    setIpAddress = (ipAddress: string): void => {
        this.ipAddress = ipAddress;
    };

    setPort = (port: string): void => {
        this.port = port;
    };

    // Set Error callback to update redux store
    setUpdateErrorCallback = (callback: any): void => {
        this.updateErrorOnReduxStore = callback;
    };

    // Set Connected callback to update redux store
    setUpdateIsConnected = (callback: any): void => {
        this.updateIsConnectedOnReduxStore = callback;
    };

    // Set printing callback to update redux store
    setUpdateIsPrintingCallback = (callback: any): void => {
        this.updateIsPrintingOnReduxStore = callback;
    };

    onReconnecting = () => {
        console.log('Reconnecting', this.status);
    };

    onReconnect = () => {
        console.log('Reconnect', this.status);
    };

    onDisconnect = () => {
        console.log('Disconnect', 'Printer is now disconnected');

        this.updateIsConnectedOnReduxStore(false);

        // Try to reconnect
        if (this.reconnectIntervalID === null) {
            this.reconnectIntervalID = window.setInterval(() => {
                this.ePosDev.connect(this.ipAddress, this.port, this.onConnectCallback);
            }, 5000);
        }
    };

    onDeviceReceive = (res: any): void => {
        // Unlock tickets printings
        this.lock = false;

        if (this.qty !== 0) {
            this.qty = this.qty - 1;
        }

        if (this.qty === 0) {
            this.updateIsPrintingOnReduxStore(false);
        }

        if (res.success) {
            return;
        }

        // Handle error message
        const errorMsgs: Record<string, string> = {
            ASB_NO_RESPONSE: 'notifications.printer_error.ASB_NO_RESPONSE', // No printer response
            ASB_OFF_LINE: 'notifications.printer_error.ASB_OFF_LINE', // Offline status
            ASB_COVER_OPEN: 'notifications.printer_error.ASB_COVER_OPEN', // Cover is open
            ASB_WAIT_ON_LINE: 'notifications.printer_error.ASB_WAIT_ON_LINE', // Waiting for online recovery
            ASB_MECHANICAL_ERR: 'notifications.printer_error.ASB_MECHANICAL_ERR', // Mechanical error generated
            ASB_AUTOCUTTER_ERR: 'notifications.printer_error.ASB_AUTOCUTTER_ERR', // Auto cutter error generated
            ASB_UNRECOVER_ERR: 'notifications.printer_error.ASB_UNRECOVER_ERR', // Unrecoverable error generated
            ASB_AUTORECOVER_ERR: 'notifications.printer_error.ASB_AUTORECOVER_ERR', // Auto recovery error generated
            ASB_RECEIPT_NEAR_END: 'notifications.printer_error.ASB_RECEIPT_NEAR_END', // No paper in the roll paper near end detector
            ASB_RECEIPT_END: 'notifications.printer_error.ASB_RECEIPT_END', // No paper in the roll paper end detector
            ASB_SPOOLER_IS_STOPPED: 'notifications.printer_error.ASB_SPOOLER_IS_STOPPED', // Stop the spooler
        };

        let msg = `${this.translator('notifications.printer_error.ABS_DEFAULT')}:\n`;
        for (const errorKey in errorMsgs) {
            if (res.status & this.printer[errorKey]) {
                msg += ` ${this.translator(errorMsgs[errorKey])}\n`;
            }
        }

        this.updateIsPrintingOnReduxStore(false);
        window.alert(msg);
    };

    connect = () => {
        this.ePosDev.ondisconnect = null;
        this.ePosDev.disconnect();
        this.ePosDev.connect(this.ipAddress, this.port, this.onConnectCallback);
    };

    onConnectCallback = (result: any): void => {
        if (this.reconnectIntervalID) clearInterval(this.reconnectIntervalID);
        this.reconnectIntervalID = null;
        this.ePosDev.ondisconnect = this.onDisconnect;

        if (result === CONNECTION_MESSGE_OK || result === CONNECTION_MESSAGE_OK_SLL) {
            // Retrieves the printer object

            this.updateErrorOnReduxStore(false);

            this.ePosDev.createDevice(DEVICE_ID, this.ePosDev.DEVICE_TYPE_PRINTER, OPTIONS, this.createDevice);
        } else {
            // Display an error messages
            console.error('Error to connect the printer');

            this.updateErrorOnReduxStore(true);

            // Try to reconnect
            window.setTimeout(() => {
                this.ePosDev.connect(this.ipAddress, this.port, this.onConnectCallback);
            }, 5000);
        }
    };

    private createDevice = (deviceObj: any, code: any): void => {
        this.retry++;

        if (code === 'OK') {
            this.updateIsConnectedOnReduxStore(true);

            this.printer = deviceObj;
            this.status = code;
            this.printer.onreceive = this.onDeviceReceive;
            this.retry = 0;

            console.debug('Printer ready');
        } else if (code === 'DEVICE_IN_USE') {
            console.debug('Printer already in use, retry in 3s');
            this.updateIsConnectedOnReduxStore(false);

            if (this.retry < MAX_CONNECTION_RETRY) {
                setTimeout(() => {
                    this.ePosDev.createDevice(DEVICE_ID, this.ePosDev.DEVICE_TYPE_PRINTER, OPTIONS, this.createDevice);
                }, 3000);
                this.status = 'RETRY';
            }
        }
    };

    private isConnected = (): void => {
        // Force printer to verify a job and then if the printer is not connected this will run the event onDisconnect
        // if (this.printer) this.printer.getPrintJobStatus();
    };
}

export default Printer;
