import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { toggleGuestsAndMembers } from 'store/appSlice';
import { guests_members } from 'types/app';

interface IProps {
    readonly guests: boolean;
    readonly members: boolean;
}

const Buttons: React.FC<IProps> = ({ guests, members }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onClick = (e: React.MouseEvent | React.TouchEvent, type: guests_members): void => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(toggleGuestsAndMembers(type));
    };

    return (
        <div className="guests-and-members-buttons">
            {guests && (
                <button className="btn btn-success guests-btn" onClick={(e) => onClick(e, 'guest')}>
                    <strong>
                        <i className="fa-solid fa-users" /> {t('customer.guests')}
                    </strong>
                </button>
            )}
            {members && (
                <button className="btn btn-primary members-btn" onClick={(e) => onClick(e, 'member')}>
                    <strong>
                        <i className="fa-solid fa-users" /> {t('customer.members')}
                    </strong>
                </button>
            )}
        </div>
    );
};

export default Buttons;
