import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Keyboard from 'components/Keyboard';
import DiscountSwitchButton from 'components/DiscountSwitchButton';
import ActionsToolbar from 'components/ActionsToolbar';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleDiscountVirtualKeyboard } from 'store/appSlice';
import { updateDiscountPercent, updateDiscountResult } from 'store/basketSlice';

const DiscountVirtualKeyboard: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const discount_virtual_keyboard_open = useAppSelector((state) => state.application.discount_virtual_keyboard_open);
    const [percent, setPercent] = useState(true);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (discount_virtual_keyboard_open) {
            setPercent(true);
            setInput('');
        }
    }, [discount_virtual_keyboard_open]);

    const toggleKeyboard = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();

        // Only toggle keyboard if it's already open
        if (discount_virtual_keyboard_open) dispatch(toggleDiscountVirtualKeyboard());
    };

    const toggleDiscountSwitchButton = (value: boolean) => {
        setPercent(value);

        // If switch back to percent remove convert double to int
        if (value && input !== '') setInput(Math.trunc(parseInt(input)).toString());
    };

    const onCancelAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        dispatch(toggleDiscountVirtualKeyboard());
        setInput('');
    };

    const onContinueAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        dispatch(toggleDiscountVirtualKeyboard());

        // Set discount to redux store
        if (percent) {
            dispatch(updateDiscountPercent(parseInt(input)));
        } else {
            dispatch(updateDiscountResult(parseFloat(input) * 100)); // Set in cents
        }
    };

    const checkInputError = (value: string) => {
        if (percent && parseInt(value) > 100) return true;

        return !value;
    };

    const clearInput = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        setInput('');
    };

    if (discount_virtual_keyboard_open) {
        return (
            <>
                <div className="overlay-dark" onClick={toggleKeyboard} />
                <div className="keyboard-wrapper-discount bg-gray">
                    <div className="keyboard-discount-input">
                        <div className="delete-icon" onClick={clearInput}>
                            <i className="far fa-times-circle" />
                        </div>
                        <div className={cx(checkInputError(input) ? 'text-danger' : '')}>{input}</div>
                    </div>
                    <div className="keyboard-discount">
                        <Keyboard updateInput={(value) => setInput(value)} input={input} decimal={!percent} />
                        <DiscountSwitchButton toggle={toggleDiscountSwitchButton} />
                    </div>
                    <ActionsToolbar
                        size={64}
                        radius={false}
                        continue_text={t('msc.confirm')}
                        cancel_action={onCancelAction}
                        continue_action={onContinueAction}
                        continue_disabled={checkInputError(input)}
                    />
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default DiscountVirtualKeyboard;
