import React from 'react';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly setCash: (value: string) => void;
    readonly disabled: boolean;
}

const CashChoiceButton: React.FC<IProps> = ({ setCash, disabled }) => {
    const clicked = (e: React.MouseEvent | React.TouchEvent, key: string) => {
        e.preventDefault();
        e.stopPropagation();

        setCash(key);
    };

    const renderButton = (value: string, className: string) => (
        <button
            className={`btn btn-light ${className}`}
            onClick={(event) => clicked(event, value)}
            onMouseDown={unFocusHtmlButton}
            disabled={disabled}
        >
            {value}
        </button>
    );

    return (
        <div className="keyboard-payment-cash-choice">
            {renderButton('10', 'cash-key-start')}
            {renderButton('20', 'cash-key-middle')}
            {renderButton('50', 'cash-key-middle')}
            {renderButton('100', 'cash-key-end')}
        </div>
    );
};

export default CashChoiceButton;
