import React from 'react';
import numbro from 'numbro';
import language from 'utils/numbro_fr-CH';

// @ts-ignore
numbro.registerLanguage(language);

export const NODE_ENV_DEV = 'development';
export const NODE_ENV_PROD = 'production';

/**
 * Unfocus HTML button
 */
export const unFocusHtmlButton = (event: React.MouseEvent) => {
    event.preventDefault();
};

/**
 * Format number to currency
 */
export const formatCurrency = (n: number, currency?: string): string => {
    if (!n && n !== 0) return '';
    n = n / 100;
    numbro.setLanguage('fr-CH');

    if (!currency) return numbro(n).format({ mantissa: 2, thousandSeparated: true });
    return currency + ' ' + numbro(n).format({ mantissa: 2, thousandSeparated: true });
};

/**
 * Format number to currency, with .– instead of .00
 */
export const shortFormatCurrency = (n: number, currency?: string): string => {
    if (!n && n !== 0) return '';
    n = n / 100;
    numbro.setLanguage('fr-CH');

    let format: any;
    if (Number.isInteger(n)) {
        format = { thousandSeparated: true, output: 'currency', currencySymbol: '.–', mantissa: 0 };
    } else {
        format = { mantissa: 2, thousandSeparated: true };
    }

    if (!currency) return numbro(n).format(format);
    return currency + ' ' + numbro(n).format(format);
};

export const shortenLabel = (label: string) => {
    label = label.replace('Abonnement', 'Abo');
    label = label.replace('individuelle', '');
    return label;
};

export const FZ_MASTER_PWD = '1337';
