import React from 'react';
import cx from 'classnames';
import { formatCurrency, unFocusHtmlButton } from 'utils/helper';
import { BasketItem as Item } from 'types/basket';
import { useAppDispatch } from 'store';
import { addTicketToBasket, removeTicketsToBasket, removeTicketToBasket } from 'store/basketSlice';

interface IProps {
    readonly item: Item;
    readonly index: number;
}

const BasketItem: React.FC<IProps> = ({ item, index }) => {
    const dispatch = useAppDispatch();

    const onClickRemoveTicket = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(removeTicketToBasket(item.org_item));
    };

    const onClickAddTicket = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(addTicketToBasket(item.org_item));
    };

    const onClickRemoveTickets = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(removeTicketsToBasket(item));
    };

    return (
        <div className={cx('basket-list-row', index % 2 === 0 ? 'even' : 'odd')}>
            <div className="item-description">{item.label}</div>
            <div className="item-quantity">
                <button
                    className="btn btn-dark btn-circle mr-2"
                    onMouseDown={unFocusHtmlButton}
                    disabled={item.quantity === 1}
                    onClick={onClickRemoveTicket}
                >
                    <i className="fas fa-minus" />
                </button>{' '}
                {item.quantity}{' '}
                <button
                    className="btn btn-dark btn-circle ml-2"
                    onMouseDown={unFocusHtmlButton}
                    onClick={onClickAddTicket}
                >
                    <i className="fas fa-plus" />
                </button>
            </div>
            <div className="item-price">
                <span className="text-ticket-table">{formatCurrency(item.price_unit)}</span>
            </div>
            <div className="item-total">
                <span className="text-ticket-table">{formatCurrency(item.amount)}</span>
            </div>
            <div className="item-delete">
                <button
                    className="btn btn-danger btn-circle"
                    onMouseDown={unFocusHtmlButton}
                    onClick={onClickRemoveTickets}
                >
                    <i className="fas fa-times" />
                </button>
            </div>
        </div>
    );
};

export default BasketItem;
