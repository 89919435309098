import React from 'react';
import ManualEntryVirtualKeyboard from 'components/ManualEntryVirtualKeyboard';

const ManualEntry: React.FC = () => {
    return (
        <div className="container-fluid h-100 d-flex flex-column justify-content-between p-4">
            <ManualEntryVirtualKeyboard />
        </div>
    );
};

export default ManualEntry;
