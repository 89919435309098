import React, { useContext, useState } from 'react';
import IndexedDBContext from 'utils/IndexedDB';
import BrowserStorage from 'utils/BrowserStorage';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { setLocked, handleAppLogout, closeUserActions } from 'store/appSlice';
import { getAccount, closeSession } from 'store/apiSlice';
import { BasketState } from 'store/basketSlice';

interface IProps {
    printDayCount: () => void;
}
const browserStorage = new BrowserStorage();

const UserActions: React.FC<IProps> = ({ printDayCount }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const indexedDB = useContext(IndexedDBContext);
    const user_actions_open = useAppSelector((state) => state.application.user_actions_open);
    const app_is_locked = useAppSelector((state) => state.application.app_is_locked);
    const app_admin_mode = useAppSelector((state) => state.application.app_admin_mode);
    const account = useAppSelector((state) => state.application.account);
    const pos_sellers_mode = useAppSelector((state) => state.application.pos_sellers_mode);
    const pos_seller = useAppSelector((state) => state.application.pos_seller);

    const [showConfirm, setshowConfirm] = useState(false);

    const toggleConfirmModal = () => {
        setshowConfirm(!showConfirm);
    };

    const lockUser = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(closeUserActions());
        dispatch(setLocked(true));
    };

    const handleCloseUserActions = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(closeUserActions());
        setshowConfirm(false);
    };

    const logOut = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setshowConfirm(false);
        // Print Décompte
        printDayCount();
        // Close session
        const orderIds = await fetchOrderIds();
        if (orderIds) {
            console.log(orderIds);
            await dispatch(closeSession(orderIds));
        } else {
            console.error('No orderIds found');
        }
        let account_reset = account?.id;
        // Reset app
        browserStorage.clearUser();
        indexedDB.clearTableOrder();
        // dispatch(resetApiState());
        dispatch(handleAppLogout());
        if (account_reset) dispatch(getAccount(account_reset));

        // Reset current account
    };

    const handlePrint = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        printDayCount();
    };

    async function fetchOrderIds() {
        try {
            const orders: BasketState[] = await new Promise((resolve, reject) => {
                indexedDB.getAllOrders((data: BasketState[]) => {
                    resolve(data);
                });
            });
            let orderIds: number[] = [];
            orders.forEach((item: BasketState) => {
                if (item?.id) {
                    if (!isNaN(item.id)) {
                        orderIds.push(item.id);
                    }
                }
            });
            return orderIds;
        } catch (error) {
            console.error(error);
        }
    }

    const userActionsShown = () => {
        const openLocked = user_actions_open && !app_is_locked;
        const openLockedPOS = user_actions_open && app_is_locked && app_admin_mode && pos_sellers_mode;

        return openLocked || openLockedPOS;
    };

    if (userActionsShown()) {
        return (
            <>
                <div className="overlay-dark" onClick={handleCloseUserActions} />
                <div className="user-actions bg-gray text-center">
                    <div className="top-infos">
                        {account?.labels.label.fr && <div className="title">{account?.labels.label.fr}</div>}
                        {pos_sellers_mode && pos_seller && (
                            <div className="title">
                                {pos_seller.name} - {pos_seller.id}
                            </div>
                        )}
                    </div>
                    {!showConfirm && (
                        <div className="actions">
                            <h5 className="fw">{t('user_actions.daycount')}</h5>
                            <button className="btn btn-primary" onClick={handlePrint}>
                                <i className="fas fa-print mr-2"></i>
                                {t('msc.print')}
                            </button>
                            <button className="btn btn-warning" onClick={toggleConfirmModal}>
                                <i className="fas fa-arrow-right-from-bracket mr-2"></i>
                                {t('user_actions.close')}
                            </button>
                            {!app_is_locked && (
                                <>
                                    <h5 className="fw">{t('user_actions.other-actions')}</h5>
                                    <button className="btn btn-primary" onClick={lockUser}>
                                        <i className="fas fa-lock mr-2" />
                                        {t('user_actions.lock')}
                                    </button>
                                    <button className="btn btn-success" onClick={handleCloseUserActions}>
                                        <i className="fas fa-check mr-2" />
                                        {t('msc.close')}
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                    {showConfirm && (
                        <>
                            <p className="">{t('user_actions.confirm-text')}</p>
                            <div className="actions">
                                <button className="btn btn-danger" onClick={toggleConfirmModal}>
                                    <i className="far fa-times-circle mr-2"></i>
                                    {t('msc.cancel')}
                                </button>
                                <button className="btn btn-success" onClick={logOut}>
                                    <i className="fas fa-check mr-2" />
                                    {t('msc.confirm')}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default UserActions;
