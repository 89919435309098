import React, { useEffect, useState } from 'react';
import Keyboard from 'components/Keyboard';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { updateManualTicketPrice } from 'store/basketSlice';
import { toggleManualEntry } from 'store/appSlice';

const ManualEntryVirtualKeyboard: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const manual_entry = useAppSelector((state) => state.application.manual_entry);
    const manual_ticket_price = useAppSelector((state) => state.basket.manual_ticket_price);
    const raw_items = useAppSelector((state) => state.basket.raw_items);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (manual_entry && manual_ticket_price !== 0 && raw_items.length > 0) {
            setInput('');
            dispatch(updateManualTicketPrice(0));
            dispatch(toggleManualEntry());
        }
    }, [raw_items, manual_entry, manual_ticket_price]);

    const onClickClose = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Clear value and close
        setInput('');
        dispatch(updateManualTicketPrice(0));
        dispatch(toggleManualEntry());
    };

    const onUpdateValue = (value: string) => {
        setInput(value);
        dispatch(updateManualTicketPrice(parseFloat(value) * 100));
    };

    const clearInput = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        setInput('');
        dispatch(updateManualTicketPrice(0));
    };

    return (
        <div className="keyboard-wrapper-manual_entry bg-gray">
            <div>
                <button className="btn btn-dark btn-close" onClick={onClickClose}>
                    <i className="fas fa-times" />
                </button>
            </div>
            <div className="text-information">{t('order.manual_ticket')}.</div>
            <div className="keyboard-manual_entry-input">
                <div className="delete-icon" onClick={clearInput}>
                    <i className="far fa-times-circle" />
                </div>
                <div>{input}</div>
            </div>
            <div className="keyboard-manual_entry">
                <Keyboard updateInput={onUpdateValue} input={input} />
            </div>
        </div>
    );
};

export default ManualEntryVirtualKeyboard;
