import { connect, ConnectedProps } from 'react-redux';
import App from 'App';
import { updateError, updateIsConnected, updateIsPrinting, updatePort } from 'store/printerSlice';
import { toggleAppConf, toggleResume, updateNodeEnvironment, setAdminUnlocked } from 'store/appSlice';
import { resetBasket, updateDate } from 'store/basketSlice';
import { getAccounts } from 'store/apiSlice';
import { AppDispatch, RootState } from 'store';
import { node_environment } from 'types/app';

const mapStateToProps = (state: RootState) => ({
    // printer
    ip_address: state.printer.ip_address,
    port: state.printer.port,
    printer_is_disabled: state.printer.is_disabled,
    printer_is_connected: state.printer.is_connected,
    printer_error: state.printer.error,
    printer_is_printing: state.printer.is_printing,
    // application
    locale: state.application.locale,
    node_environment: state.application.environment,
    manual_entry: state.application.manual_entry,
    resume_open: state.application.resume_open,
    app_conf_open: state.application.app_conf_open,
    guests_and_members_open: state.application.guests_and_members_open,
    selected_history_order: state.application.selected_history_order,
    admin_unlocked: state.application.admin_unlocked,
    app_is_locked: state.application.app_is_locked,
    pos_sellers_mode: state.application.pos_sellers_mode,
    pos_seller: state.application.pos_seller,
    app_admin_mode: state.application.app_admin_mode,
    // basket
    basket: state.basket,
    // api
    order: state.api.order,
    is_posting_basket: state.api.is_posting_basket,
    posting_basket_error: state.api.posting_basket_error,
    current_main_event: state.application.current_main_event,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updatePort: (value: string) => {
        dispatch(updatePort(value));
    },
    updateError: (value: boolean) => {
        dispatch(updateError(value));
    },
    updateIsConnected: (value: boolean) => {
        dispatch(updateIsConnected(value));
    },
    updateNodeEnvironment: (value: node_environment) => {
        dispatch(updateNodeEnvironment(value));
    },
    resetBasket: () => {
        dispatch(resetBasket());
    },
    toggleAppConf: () => {
        dispatch(toggleAppConf());
    },
    updateIsPrinting: (value: boolean) => {
        dispatch(updateIsPrinting(value));
    },
    toggleResume: () => {
        dispatch(toggleResume());
    },
    updateOrderDate: () => {
        dispatch(updateDate());
    },
    setAdminUnlocked: () => {
        dispatch(setAdminUnlocked());
    },
    getAccounts: () => {
        dispatch(getAccounts());
    },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;
const AppContainer = connector(App);

export default AppContainer;
