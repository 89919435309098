import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TimestampComponent: React.FC<{ timestamp: number }> = ({ timestamp }) => {
    const { i18n } = useTranslation();
    moment.locale(i18n.language || 'fr');
    const getTimeDifference = () => {
        const now = moment();
        const time = moment.unix(timestamp);

        const minutesDifference = now.diff(time, 'minutes');

        if (minutesDifference <= 60) {
            return `${i18n.t('msc.minutesago-prefix')} ${minutesDifference} ${i18n.t('msc.minutesago-suffix')}`;
        } else {
            return time.format('D.MM.YY HH:mm');
        }
    };

    return <div>{getTimeDifference()}</div>;
};

export default TimestampComponent;
