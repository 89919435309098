import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sort } from 'fast-sort';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleGuestsAndMembers } from 'store/appSlice';
import Filter from 'components/GuestsAndMembers/Filter';
import List from 'components/GuestsAndMembers/List';
import { Customer } from 'types/customer';
import { clearCustomers, fetchCustomers } from 'store/apiSlice';

const Modal: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const guests_and_members_open = useAppSelector((state) => state.application.guests_and_members_open);
    const is_fetching_customers = useAppSelector((state) => state.api.is_fetching_customers);
    const customers = useAppSelector((state) => state.api.customers);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        // Fetch customers from API
        dispatch(fetchCustomers())
            .unwrap()
            .catch((e) => window.alert("Une erreur s'est produite, mise à jour des guests & membres impossible."));

        return () => {
            dispatch(clearCustomers());
        };
    }, []);

    const closeGuestsModal = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        // Only toggle if it's already open
        if (guests_and_members_open) dispatch(toggleGuestsAndMembers(guests_and_members_open));
    };

    const onFilterChange = (value: string): void => {
        setFilter(filter + value);
    };

    const onDelete = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setFilter(filter.slice(0, -1));
    };

    const filterCustomers = (customers: Customer[]): Customer[] => {
        if (guests_and_members_open === 'member') {
            return sort(
                customers.filter((c) => c.type === 'member' && c.lastname.toLowerCase().startsWith(filter)),
            ).asc([(c) => c.lastname.toLowerCase(), (c) => c.firstname.toLowerCase()]);
        } else {
            return sort(
                customers.filter(
                    (c) =>
                        (c.type === 'guestlist' || c.type === 'permanent-guestlist') &&
                        c.lastname.toLowerCase().startsWith(filter),
                ),
            ).asc([(c) => c.lastname.toLowerCase(), (c) => c.firstname.toLowerCase()]);
        }
    };

    return (
        <LoadingOverlay
            className="guests-and-members-wrapper"
            active={is_fetching_customers}
            spinner
            text={t('customer.loading_customers')}
        >
            <div className="bg-gray guests-and-members-container d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="title">{t('customer.' + guests_and_members_open + 's')}</div>
                    {filter.length > 0 && (
                        <div className="d-flex align-items-end" style={{ minWidth: 360 }}>
                            <div className="flex-grow-1 input">{filter}</div>
                            <button className="btn btn-dark" onClick={onDelete} style={{ width: 60 }}>
                                <i className="fas fa-backspace" />
                            </button>
                        </div>
                    )}
                    <button className="btn btn-dark btn-close" onClick={closeGuestsModal}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <Filter onChange={onFilterChange} activeFilters={filter} />
                <List customers={filterCustomers(customers)} />
            </div>
        </LoadingOverlay>
    );
};

export default Modal;
