import React from 'react';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { WithTranslation, withTranslation } from 'react-i18next';
import Printer from 'utils/PrinterTickets';
import IndexedDBContext from 'utils/IndexedDB';
import TicketsAvailableList from 'components/TicketsAvailableList';
import DiscountVirtualKeyboard from 'components/DiscountVirtualKeyboard';
import VirtualKeyboard from 'components/Payment/VirtualKeyboard';
import ZipVirtualKeyboard from 'components/ZipVirtualKeyboard';
import DefaultEntry from 'components/DefaultEntry';
import ManualEntry from 'components/ManualEntry';
import Navbar from 'components/Navbar';
import HistoryList from 'components/History/HistoryList';
import HistoryPrint from 'components/History/HistoryPrint';
import Resume from 'components/Resume';
import ApplicationSettings from 'components/ApplicationSettings';
import MainEventsListModal from 'components/MainEventsListModal';
import Modal from 'components/GuestsAndMembers/Modal';
import DevHelper from 'components/DevHelper';
import UserActions from 'components/UserActions';
import { PropsFromRedux } from 'AppContainer';
import { BasketState } from 'store/basketSlice';
import LockScreen from 'components/LockScreen';
import Spinner from 'components/Spinner';

LoadingOverlay.propTypes = undefined;

class App extends React.Component<PropsFromRedux & WithTranslation> {
    private printer: Printer;
    private storage: any;

    constructor(props: PropsFromRedux & WithTranslation) {
        super(props);

        // Create a new printer
        this.printer = new Printer(this.props.t);
        this.printer.setUpdateErrorCallback(this.callbackPrinterError);
        this.printer.setUpdateIsConnected(this.callbackPrinterIsConnected);
        this.printer.setUpdateIsPrintingCallback(this.callbackPrinterIsPrinting);

        this.storage = null;
    }

    componentDidMount() {
        this.storage = this.context;

        // If a printer IP address and port are already configured, connect the printer
        if (!this.props.printer_is_disabled) {
            if (this.props.ip_address && this.props.port) {
                this.connectToPrinter();
            }
        } else {
            console.debug('Printer disabled in the settings');
        }

        // Refresh accounts (in order to refresh event list)
        if (this.props.current_main_event) {
            this.props.getAccounts();
        }
    }

    componentDidUpdate(prevProps: PropsFromRedux) {
        // Print ticket at the end of the process
        if (this.props.order && this.props.order !== prevProps.order && this.props.posting_basket_error === null) {
            // Update basket with the current timestamp
            this.props.updateOrderDate();
            // Show resume modal
            this.props.toggleResume();

            // If printer enabled print tickets
            if (this.props.current_main_event && this.props.order && !this.props.printer_is_disabled) {
                this.printer.prepareTickets(this.props.order, this.props.current_main_event);
            }
        }
    }

    callbackPrinterError = () => {
        // Update store
        this.props.updateError(true);
        this.props.updateIsConnected(false);
    };

    callbackPrinterIsConnected = (value: boolean) => {
        // Update store
        if (value) this.props.updateError(!value);
        this.props.updateIsConnected(value);
    };

    callbackPrinterIsPrinting = (value: boolean) => {
        this.props.updateIsPrinting(value);
    };

    connectToPrinter = () => {
        this.printer.setLocale(this.props.locale);
        this.printer.setIpAddress(this.props.ip_address);
        this.printer.setPort(this.props.port);
        this.printer.connect();
    };

    printDayCount = () => {
        // Get all orders from indexedDb
        this.storage.getAllOrders((data: BasketState[]) => {
            // If no orders, skip
            if (data.length === 0) {
                // return window.alert(this.props.t('notifications.no_orders'));
                return;
            }

            if (this.props.printer_is_connected && this.props.current_main_event) {
                this.printer.printDayCount(data, this.props.current_main_event);
            }
        });
    };

    printQuittance = () => {
        if (this.props.order && this.props.current_main_event) {
            this.printer.printQuittance(this.props.order, this.props.current_main_event);
        }
    };

    reprintTicket = () => {
        if (this.props.selected_history_order && this.props.current_main_event) {
            this.printer.reprintTicket(this.props.selected_history_order, this.props.current_main_event);
        }
    };

    reprintQuittance = () => {
        if (this.props.selected_history_order && this.props.current_main_event) {
            this.printer.reprintQuittance(this.props.selected_history_order, this.props.current_main_event);
        }
    };

    removeTicketFromPrinterMemory = () => {
        if (this.props.selected_history_order && this.props.current_main_event) {
            this.printer.removeTicketFromPrinterMemory(
                this.props.selected_history_order,
                this.props.current_main_event,
            );
        }
    };

    showTicketsAvailableList = () => {
        // const isLocked = this.props.app_is_locked;
        const posIsActive =
            this.props.pos_sellers_mode &&
            this.props.pos_seller &&
            !this.props.app_admin_mode &&
            !this.props.app_is_locked;
        const regularIsActive = !this.props.app_is_locked && !this.props.pos_sellers_mode;
        return posIsActive || regularIsActive;
    };

    render() {
        const { manual_entry, printer_is_printing, is_posting_basket, printer_is_disabled, node_environment } =
            this.props;

        return (
            <>
                <Navbar />
                {this.showTicketsAvailableList() && (
                    <LoadingOverlay
                        className="d-flex ch-100"
                        active={(!printer_is_disabled && printer_is_printing) || is_posting_basket}
                        spinner
                        text={
                            is_posting_basket
                                ? this.props.t('notifications.sending_data')
                                : this.props.t('notifications.printing')
                        }
                    >
                        <TicketsAvailableList />
                        <div className="flex-even" key="basket-list">
                            {manual_entry ? <ManualEntry /> : <DefaultEntry />}
                        </div>
                    </LoadingOverlay>
                )}
                <DiscountVirtualKeyboard />
                <VirtualKeyboard />
                <ZipVirtualKeyboard />
                <HistoryList />
                <HistoryPrint
                    reprintTicket={this.reprintTicket}
                    reprintQuittance={this.reprintQuittance}
                    removeTicketFromPrinterMemory={this.removeTicketFromPrinterMemory}
                />
                <UserActions printDayCount={this.printDayCount} />
                <Resume printQuittance={this.printQuittance} />
                <ApplicationSettings connectToPrinter={this.connectToPrinter} />
                <MainEventsListModal />
                <LockScreen />
                {this.props.guests_and_members_open && <Modal />}

                {this.props.app_is_locked && (
                    <div className="lock-icon">
                        <i className={'fa-solid fa-lock '} />
                    </div>
                )}
                {node_environment === 'development' && <DevHelper />}
                <Spinner />
            </>
        );
    }
}

App.contextType = IndexedDBContext;

export default withTranslation()(App);
