import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/helper';
import IndexedDBContext from 'utils/IndexedDB';
import { useAppDispatch, useAppSelector } from 'store';
import { resetBasket } from 'store/basketSlice';
import { toggleResume } from 'store/appSlice';

interface IProps {
    readonly printQuittance: () => void;
}

const Resume: React.FC<IProps> = ({ printQuittance }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const storage = useContext(IndexedDBContext);
    const resume_open = useAppSelector((state) => state.application.resume_open);
    const basket = useAppSelector((state) => state.basket);
    const printer_is_connected = useAppSelector((state) => state.printer.is_connected);

    const onClickOkButton = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Save data to storage
        storage.addOrder(basket);

        dispatch(resetBasket());
        dispatch(toggleResume());
    };

    const onClickPrintQuittance = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        printQuittance();
    };

    if (resume_open) {
        return (
            <>
                <div className="overlay-dark" />
                <div className="resume-wrapper bg-gray">
                    <div className="d-flex justify-content-between">
                        <div>{t('order.total')} :</div>
                        <div>{formatCurrency(basket.total, 'CHF')}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>{t('order.cashed')} :</div>
                        <div>{formatCurrency(basket.cash, 'CHF')}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>{t('order.cashed_back')} :</div>
                        <div>{formatCurrency(basket.change, 'CHF')}</div>
                    </div>
                    <div className="keyboard-actions mt-3">
                        <button
                            className="btn btn-info btn-cancel"
                            onClick={onClickPrintQuittance}
                            style={{ fontSize: '1.5rem' }}
                            disabled={!printer_is_connected}
                        >
                            <i className="fas fa-print" /> {t('order.print_receipt')}
                        </button>
                        <button
                            className="btn btn-success btn-continue"
                            onClick={onClickOkButton}
                            style={{ fontSize: '1.5rem' }}
                        >
                            <i className="fas fa-check" /> {t('msc.ok')}
                        </button>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default Resume;
