import React from 'react';
import { unFocusHtmlButton } from 'utils/helper';
import { useTranslation } from 'react-i18next';

interface IProps {
    readonly size?: number;
    readonly radius?: boolean;
    readonly icon?: string;
    readonly continue_text: string;
    readonly continue_disabled: boolean;
    readonly cancel_action: (event: React.MouseEvent | React.TouchEvent) => void;
    readonly continue_action: (event: React.MouseEvent | React.TouchEvent) => void;
}

const ActionsToolbar: React.FC<IProps> = ({
    size = 96,
    radius = true,
    icon = 'check',
    continue_text,
    continue_disabled,
    cancel_action,
    continue_action,
}) => {
    const { t } = useTranslation();

    return (
        <div className="keyboard-actions" key="keyboard-actions" style={{ height: size + 'px' }}>
            <button
                className="btn btn-danger btn-cancel"
                onMouseDown={unFocusHtmlButton}
                onClick={cancel_action}
                style={{ borderRadius: radius ? '0.25rem' : '0' }}
            >
                <strong>
                    <i className="far fa-times-circle" /> {t('msc.cancel')}
                </strong>
            </button>
            <button
                className="btn btn-success btn-continue"
                disabled={continue_disabled}
                onMouseDown={unFocusHtmlButton}
                onClick={continue_action}
                style={{ borderRadius: radius ? '0.25rem' : '0' }}
            >
                <strong>
                    <i className={'fas fa-' + icon} /> {continue_text}
                </strong>
            </button>
        </div>
    );
};

export default ActionsToolbar;
