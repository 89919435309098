import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { updateCurrentMainEvent } from 'store/appSlice';
import { toggleChooseEvent, triggerCreateNewSession } from 'store/appSlice';
import { MainEvent } from 'types/event';
import ActionsToolbar from 'components/ActionsToolbar';
import BrowserStorage from 'utils/BrowserStorage';

const browserStorage = new BrowserStorage();

const MainEventsListModal: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const app_choose_event_open = useAppSelector((state) => state.application.app_choose_event_open);
    const main_events = useAppSelector((state) => state.application.account?.active_main_events || []);
    const [eventId, setEventId] = useState<number | null>(null);

    useEffect(() => {
        if (app_choose_event_open) {
            console.debug('Refresh main events');
            // TODO: refresh main events?
        }
    }, [app_choose_event_open]);

    const closeEventListModal = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (app_choose_event_open) dispatch(toggleChooseEvent());
    };

    const onEventListChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        setEventId(parseInt(e.target.value));
    };

    const onValidateClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const main_event = main_events.find((e) => e.id === eventId) as MainEvent;
        dispatch(updateCurrentMainEvent(main_event));
        browserStorage.setCurrentMainEvent(main_event);
        dispatch(triggerCreateNewSession());
        closeEventListModal(e);
    };

    if (app_choose_event_open) {
        return (
            <>
                <div className="overlay-dark" onClick={closeEventListModal} />
                <div className="app-settings-wrapper bg-gray" style={{ width: '444px' }}>
                    <div className="title">{t('events.choose')}</div>
                    <div className="form-group">
                        <select className="form-control form-control-lg" name="event_list" onChange={onEventListChange}>
                            <option value="">{t('events.please_choose')}</option>
                            {main_events.map((main_event) => {
                                return (
                                    <option value={main_event.id} key={main_event.id}>
                                        {main_event.start_date
                                            ? moment(main_event.start_date).format('DD.MM') + ' - '
                                            : ''}
                                        {main_event.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <ActionsToolbar
                        size={64}
                        radius={false}
                        continue_text={t('msc.confirm')}
                        cancel_action={closeEventListModal}
                        continue_action={onValidateClick}
                        continue_disabled={false}
                    />
                </div>
            </>
        );
    }

    return null;
};

export default MainEventsListModal;
