import React, { useContext } from 'react';
import BrowserStorage from 'utils/BrowserStorage';
import IndexedDBContext from 'utils/IndexedDB';
import { useAppSelector } from 'store';

const browserStorage = new BrowserStorage();

const DevHelper: React.FC = () => {
    const indexedDB = useContext(IndexedDBContext);
    const session_uuid = useAppSelector((state) => state.application.session_uuid);
    const pos_sellers_mode = useAppSelector((state) => state.application.pos_sellers_mode);
    const app_is_locked = useAppSelector((state) => state.application.app_is_locked);
    const current_main_event = useAppSelector((state) => state.application.current_main_event);
    const pos_seller = useAppSelector((state) => state.application.pos_seller);
    const app_admin_mode = useAppSelector((state) => state.application.app_admin_mode);

    const handleReset = () => {
        browserStorage.clear();
        indexedDB.clearTableOrder();
        setTimeout(() => {
            window.location.reload();
            console.clear();
        }, 300);
    };

    return (
        <div
            className="fixed-bottom d-inline-block bg-white border"
            style={{ fontSize: '0.7rem', padding: '5px', opacity: 0.8, right: 'auto' }}
        >
            <button className="btn btn-warning mb-2" onClick={handleReset} style={{ fontSize: '0.7rem' }}>
                App Reset
            </button>
            <div>
                <strong>Admin mode: </strong>
                {app_admin_mode ? 'ON' : 'OFF'}
            </div>
            <div>
                <strong>Mode: </strong>
                {pos_sellers_mode ? 'POS seller' : 'Regular'}
            </div>
            <div>
                <strong>Locked: </strong>
                {app_is_locked ? 'True' : 'False'}
            </div>
            {pos_seller && (
                <div>
                    <strong>Pos_seller: </strong>
                    {pos_seller.name}
                </div>
            )}
            {session_uuid && (
                <div>
                    <strong>Session: </strong>
                    {session_uuid.substring(0, 8)}...
                </div>
            )}
            {current_main_event && (
                <div>
                    <strong>Event: </strong>
                    {current_main_event.labels?.label.fr}
                </div>
            )}
        </div>
    );
};

export default DevHelper;
