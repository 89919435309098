import React from 'react';
import Switch from 'react-switch';
import { GeneralSettingsProps } from 'types/settings';

const GeneralSettings: React.FC<GeneralSettingsProps> = ({ printerIsDisabled, onPrinterSwitchChange }) => {
    return (
        <div className="d-flex align-items-center">
            <span className="mr-2">Ne pas imprimer les billets et les marquer comme scannés</span>
            <Switch
                checked={printerIsDisabled}
                uncheckedIcon={false}
                checkedIcon={false}
                activeBoxShadow="0 0 2px 3px #218838"
                onChange={onPrinterSwitchChange}
            />
        </div>
    );
};

export default GeneralSettings;
