import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BrowserStorage from 'utils/BrowserStorage';
import {
    ensureTokenIsFresh,
    HOST,
    CLIENT_ID,
    CLIENT_SECRET,
    REQUEST_TIMEOUT,
    accessToken,
    setAccessToken,
    setRefreshToken,
} from 'utils/token';
import { RootState } from 'store/index';
import { setAccount, setLocked, setPOSMode, openAppLogin } from 'store/appSlice';

import { BasketItem } from 'types/basket';
import { ApiGetCustomers, CustomerType } from 'types/customer';
import { MainEvent } from 'types/event';
import { Account } from 'types/account';
import {
    ApiAuthResponse,
    ApiPostGuestResponse,
    ApiPostOrderResponse,
    ApiCancelOrderResponse,
    ApiSessionStartResponse,
    ApiState,
} from 'types/api';

const browserStorage = new BrowserStorage();

export const initialState: ApiState = {
    is_posting_auth: false,
    posting_auth_error: null,
    is_getting_accounts: false,
    get_accounts_error: null,
    is_getting_account: false,
    get_account_error: null,
    is_posting_basket: false,
    posting_basket_error: null,
    is_cancelling_order: false,
    cancel_order_error: null,
    is_fetching_customers: false,
    fetching_customers_error: null,
    is_posting_guest: false,
    is_creating_session: false,
    create_session_error: null,
    posting_guest_error: null,
    customers: [],
};

// Generic API call
const fetchFromApi = async (url: string, options: RequestInit, skipTokenRefresh?: boolean) => {
    if (!skipTokenRefresh) {
        await ensureTokenIsFresh();
    }
    const headers = new Headers({
        ...options.headers,
        Accept: 'application/json',
    });
    if (!skipTokenRefresh) {
        headers.append('Authorization', `Bearer ${accessToken}`);
    }
    const response = await fetch(url, {
        ...options,
        signal: AbortSignal.timeout(REQUEST_TIMEOUT),
        headers: headers,
    });

    if (response.status === 204) {
        return { status: 204, message: 'No Content' };
    } else if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        const errorMessage =
            errorData.error_description || errorData.message || `Request failed with status ${response.status}`;
        throw new Error(errorMessage);
    }
    return response.json();
};

// Admin login
export const postAuth = createAsyncThunk<ApiAuthResponse, { username: string; password: string }, { state: RootState }>(
    'api/postAuth',
    async (args, thunkAPI) => {
        const params = new URLSearchParams();
        params.append('client_id', CLIENT_ID);
        params.append('client_secret', CLIENT_SECRET);
        params.append('grant_type', 'password');
        params.append('username', args.username);
        params.append('password', args.password);

        try {
            const url = `${HOST}/api/v2/oauth/token`;
            const jsonResponse = await fetchFromApi(
                url,
                {
                    method: 'POST',
                    body: params,
                },
                true, // skip token refresh
            );

            setAccessToken(jsonResponse.access_token);
            setRefreshToken(jsonResponse.refresh_token);
            thunkAPI.dispatch(getAccounts());

            return jsonResponse;
        } catch (e: any) {
            // console.log(e); // wrong...
            throw e;
        }
    },
);

export const getAccounts = createAsyncThunk<any, undefined, { state: RootState }>(
    'api/getAccounts',
    async (args, thunkAPI) => {
        try {
            const url = `${HOST}/api/v2/account`;
            const jsonResponse = await fetchFromApi(url, {});

            const accountIds = jsonResponse.accounts.map((account: any) => account.id);
            if (accountIds.length === 1) {
                // if only one account, fetch it
                thunkAPI.dispatch(getAccount(accountIds[0]));
            } else {
                // TODO: multiple accounts
                window.alert('Multiple accounts not supported yet');
                thunkAPI.dispatch(getAccount(accountIds[0]));
            }
        } catch (e: any) {
            throw e;
        }
    },
);

export const getAccount = createAsyncThunk<Account, number, { state: RootState }>(
    'api/getAccount',
    async (args, thunkAPI) => {
        try {
            const jsonResponse = await fetchFromApi(`${HOST}/api/v2/account/${args}`, {});

            // admin is unlocked
            // set account in storage
            browserStorage.setAccount(jsonResponse);
            thunkAPI.dispatch(setAccount(jsonResponse));
            // if pos_users exists and is not empty, force the pin login
            if (jsonResponse.pos_sellers.length > 0) {
                thunkAPI.dispatch(setPOSMode(true));
                browserStorage.setPOSSellerMode(true);
                thunkAPI.dispatch(setLocked(true));
                thunkAPI.dispatch(openAppLogin());
            } else {
                thunkAPI.dispatch(setPOSMode(false));
                browserStorage.setPOSSellerMode(false);
                thunkAPI.dispatch(setLocked(true));
                thunkAPI.dispatch(openAppLogin());
            }

            return jsonResponse;
        } catch (e: any) {
            throw e;
        }
    },
);

export const createNewSession = createAsyncThunk<ApiSessionStartResponse, undefined, { state: RootState }>(
    'api/createNewSession',
    async (args, thunkAPI) => {
        const pos_seller = thunkAPI.getState().application.pos_seller;
        const main_event = thunkAPI.getState().application.current_main_event;

        if (!main_event) return Promise.reject('No current main event');

        const bodyData = pos_seller !== null ? { pos_seller: pos_seller.id } : {};

        try {
            const url = `${HOST}/api/v2/pos-session/new/${main_event.id}`;
            const jsonResponse = await fetchFromApi(url, {
                method: 'POST',
                body: JSON.stringify(bodyData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return jsonResponse;
        } catch (e: any) {
            throw e;
        }
    },
);

export const closeSession = createAsyncThunk<any, number[], { state: RootState }>(
    'api/closeSession',
    async (orderIds, thunkAPI) => {
        const session_uuid = thunkAPI.getState().application.session_uuid;

        if (!session_uuid) return Promise.resolve('No session uuid');

        const bodyData = {
            order_ids: orderIds,
        };

        try {
            const url = `${HOST}/api/v2/pos-session/${session_uuid}/close`;
            const jsonResponse = await fetchFromApi(url, {
                method: 'PUT',
                body: JSON.stringify(bodyData),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return jsonResponse;
        } catch (e: any) {
            throw e;
        }
    },
);

export const postBasket = createAsyncThunk<ApiPostOrderResponse, undefined, { state: RootState }>(
    'api/postBasket',
    async (args, thunkAPI) => {
        // Deep copy and not shallow copy
        let body = JSON.parse(JSON.stringify(thunkAPI.getState().basket));
        // Delete some unnecessary properties
        delete body.raw_items;
        delete body.manual_ticket_price;
        delete body.date;
        delete body.id;
        body.items.forEach((item: BasketItem, index: number) => {
            delete body.items[index].id;
            body.items[index].pos_additionnal_footer = body.items[index].org_item.pos_additionnal_footer;
            delete body.items[index].org_item;
        });
        body.card = body.card ? 1 : 0;
        body.mark_ticket_as_checked = thunkAPI.getState().printer.is_disabled;
        body.pos_session = thunkAPI.getState().application.session_uuid;

        const main_event = thunkAPI.getState().application.current_main_event as MainEvent;

        try {
            const url = `${HOST}/api/v2/events/${main_event.id}/pos-order`;
            const jsonResponse = await fetchFromApi(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return jsonResponse;
        } catch (e: any) {
            throw e;
        }
    },
);

export const cancelOrder = createAsyncThunk<ApiCancelOrderResponse, undefined, { state: RootState }>(
    'api/cancelOrder',
    async (args, thunkAPI) => {
        const main_event = thunkAPI.getState().application.current_main_event as MainEvent;
        const orderToDelete = thunkAPI.getState().application.selected_history_order?.id;
        if (!orderToDelete) return Promise.reject('No order to delete');

        try {
            const url = `${HOST}/api/v2/events/${main_event.id}/pos-order/${orderToDelete}`;
            const jsonResponse = await fetchFromApi(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (jsonResponse.status === 204 || jsonResponse.status === 200) {
                return { message: 'Deleted successfully' };
            } else {
                const errorData = await jsonResponse.json();
                throw new Error(`Failed to delete order: ${errorData.message}`);
            }
        } catch (e: any) {
            throw e;
        }
    },
);

export const fetchCustomers = createAsyncThunk<ApiGetCustomers, undefined, { state: RootState }>(
    'api/fetchCustomers',
    async (args, thunkAPI) => {
        try {
            const main_event = thunkAPI.getState().application.current_main_event as MainEvent;

            const url = `${HOST}/api/v2/events/${main_event.id}/guestlist/`;
            const jsonResponse = await fetchFromApi(url, {});

            return jsonResponse;
        } catch (e: any) {
            throw e;
        }
    },
);

export const postGuest = createAsyncThunk<
    ApiPostGuestResponse,
    { uuid: string; type: CustomerType; number: number },
    { state: RootState }
>('api/postGuest', async (args, thunkAPI) => {
    const main_event = thunkAPI.getState().application.current_main_event as MainEvent;

    try {
        const url = `${HOST}/api/v2/events/${main_event.id}/guestlist/create-tickets`;
        const jsonResponse = await fetchFromApi(url, {
            method: 'POST',
            body: JSON.stringify(args),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return jsonResponse;
    } catch (e: any) {
        throw e;
    }
});

export const apiSlice = createSlice({
    name: 'api',
    initialState: initialState,
    reducers: {
        clearCustomers: (state) => {
            state.customers = [];
        },
        resetApiState: (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(postAuth.pending, (state, action) => {
            state.is_posting_auth = true;
            state.posting_auth_error = null;
        });
        builder.addCase(postAuth.fulfilled, (state, action) => {
            state.is_posting_auth = false;
        });
        builder.addCase(postAuth.rejected, (state, action) => {
            state.is_posting_auth = false;
            state.posting_auth_error = action.error;
        });

        builder.addCase(getAccounts.pending, (state, action) => {
            state.is_getting_accounts = true;
            state.get_accounts_error = null;
        });
        builder.addCase(getAccounts.fulfilled, (state, action) => {
            state.is_getting_accounts = false;
        });
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.is_getting_accounts = false;
            state.get_accounts_error = action.error;
        });

        builder.addCase(getAccount.pending, (state, action) => {
            state.is_getting_account = true;
            state.get_account_error = null;
        });
        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.is_getting_account = false;
        });
        builder.addCase(getAccount.rejected, (state, action) => {
            state.is_getting_account = false;
            state.get_account_error = action.error;
        });

        builder.addCase(createNewSession.pending, (state, action) => {
            state.is_creating_session = true;
            state.create_session_error = null;
        });
        builder.addCase(createNewSession.fulfilled, (state, action) => {
            state.is_creating_session = false;
        });
        builder.addCase(createNewSession.rejected, (state, action) => {
            state.is_creating_session = false;
            state.create_session_error = action.error;
        });

        builder.addCase(postBasket.pending, (state, action) => {
            state.is_posting_basket = true;
            state.posting_basket_error = null;
        });
        builder.addCase(postBasket.fulfilled, (state, action) => {
            state.order = action.payload;
            state.is_posting_basket = false;
        });
        builder.addCase(postBasket.rejected, (state, action) => {
            state.is_posting_basket = false;
            state.posting_basket_error = action.error;
        });

        builder.addCase(cancelOrder.pending, (state, action) => {
            state.is_cancelling_order = true;
            state.cancel_order_error = null;
        });
        builder.addCase(cancelOrder.fulfilled, (state, action) => {
            state.is_cancelling_order = false;
        });
        builder.addCase(cancelOrder.rejected, (state, action) => {
            state.is_cancelling_order = false;
            state.cancel_order_error = action.error;
        });

        builder.addCase(fetchCustomers.pending, (state, action) => {
            state.is_fetching_customers = true;
            state.fetching_customers_error = null;
        });
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.customers = action.payload.guests;
            state.is_fetching_customers = false;
        });
        builder.addCase(fetchCustomers.rejected, (state, action) => {
            state.is_fetching_customers = false;
            state.fetching_customers_error = action.error;
        });

        builder.addCase(postGuest.pending, (state, action) => {
            state.is_posting_guest = true;
            state.posting_guest_error = null;
        });
        builder.addCase(postGuest.fulfilled, (state, action) => {
            state.is_posting_guest = false;
        });
        builder.addCase(postGuest.rejected, (state, action) => {
            state.is_posting_guest = false;
            state.posting_guest_error = action.error;
        });
    },
});

export const { clearCustomers, resetApiState } = apiSlice.actions;

export default apiSlice.reducer;
