import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, unFocusHtmlButton } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleDiscountVirtualKeyboard, toggleManualEntry } from 'store/appSlice';

const BasketTotal: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const main_event = useAppSelector((state) => state.application.current_main_event);
    const total = useAppSelector((state) => state.basket.total);
    const sub_total = useAppSelector((state) => state.basket.sub_total);
    const discount = useAppSelector((state) => state.basket.discount);
    const discount_percent = useAppSelector((state) => state.basket.discount_percent);
    const taxes = useAppSelector((state) => state.basket.taxes);
    const raw_items = useAppSelector((state) => state.basket.raw_items);

    const onClickDiscount = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        dispatch(toggleDiscountVirtualKeyboard());
    };

    const onClickManualPrice = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        dispatch(toggleManualEntry());
    };

    return (
        <div className="basket-total-wrapper">
            <div className="d-flex discount-wrapper">
                <div className="d-flex justify-content-start flex-even">
                    <button
                        className="btn btn-primary mr-1"
                        onMouseDown={unFocusHtmlButton}
                        onClick={onClickDiscount}
                        disabled={raw_items.length === 0}
                        style={{ width: '50%' }}
                    >
                        <i className="fas fa-tag" /> {t('order.discount')}
                    </button>
                    <button
                        className="btn btn-primary ml-1"
                        onMouseDown={unFocusHtmlButton}
                        onClick={onClickManualPrice}
                        style={{ width: '50%' }}
                    >
                        <i className="fas fa-th" /> {t('order.free_price')}
                    </button>
                </div>
                <div className="d-flex justify-content-between flex-even">
                    <div className="d-flex flex-column ml-5">
                        <div className="text-right">
                            <strong>{t('order.sub-total')}:</strong>
                        </div>
                        <div className="text-light text-right">
                            {discount_percent !== 0 && (
                                <small>
                                    <strong>{t('order.discount_percent', { discount: discount_percent })}:</strong>
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <div className="text-right">
                            <strong>{formatCurrency(sub_total, 'CHF')}</strong>
                        </div>
                        <div className="text-light text-right">
                            <small>
                                <strong>{formatCurrency(discount, 'CHF')}</strong>
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between total-wrapper">
                <div>{t('order.total').toUpperCase()}</div>
                <div>{formatCurrency(total, 'CHF')}</div>
            </div>

            <div className="d-flex justify-content-between tva-wrapper">
                <div>
                    <i>{t('order.tva_2', { tax_rate: main_event ? parseFloat(main_event.tax_rate) * 100 : '0' })}</i>
                </div>
                <div>
                    <i>{formatCurrency(taxes, 'CHF')}</i>
                </div>
            </div>
        </div>
    );
};

export default BasketTotal;
