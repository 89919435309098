import React from 'react';
import { PrinterSettingsProps } from 'types/settings';

const PrinterSettings: React.FC<PrinterSettingsProps> = ({
    ipAddress,
    port,
    onPrinterInputChange,
    onPrinterClick,
    isPrinterConnecting,
    printerStatus,
}) => {
    return (
        <div className="ml-2" style={{ flex: 1 }}>
            <div className="section-title">Imprimante</div>
            <div className="text-center text-light">Status : {printerStatus()}</div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control form-control-lg"
                    name="address_ip"
                    placeholder="Adresse IP"
                    value={ipAddress}
                    onChange={onPrinterInputChange}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control form-control-lg"
                    name="port"
                    placeholder="Numéro de port"
                    value={port}
                    onChange={onPrinterInputChange}
                />
            </div>
            <div className="form-group">
                <button
                    className="btn btn-success btn-block btn-lg"
                    onClick={onPrinterClick}
                    disabled={isPrinterConnecting}
                >
                    Connexion
                </button>
            </div>
        </div>
    );
};

export default PrinterSettings;
