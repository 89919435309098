import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IndexedDBContext from 'utils/IndexedDB';
import { formatCurrency } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleHistoryPrint } from 'store/appSlice';
import { BasketState } from 'store/basketSlice';
import { cancelOrder } from 'store/apiSlice';

interface IProps {
    readonly reprintTicket: () => void;
    readonly reprintQuittance: () => void;
    readonly removeTicketFromPrinterMemory: () => void;
}

const HistoryPrint: React.FC<IProps> = ({ reprintTicket, reprintQuittance, removeTicketFromPrinterMemory }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const storage = useContext(IndexedDBContext);
    const history_print_open = useAppSelector((state) => state.application.history_print_open);
    const printer_is_connected = useAppSelector((state) => state.printer.is_connected);
    const printer_is_disabled = useAppSelector((state) => state.printer.is_disabled);
    const selectedHistoryOrder = useAppSelector((state) => state.application.selected_history_order);
    const [orders, setOrders] = useState<BasketState[]>([]);

    useEffect(() => {
        if (history_print_open && !selectedHistoryOrder) {
            // Get all orders on modal open
            storage.getAllOrders((data: BasketState[]) => {
                setOrders(data);
            });
        } else {
            // Reset data on modal close
            setOrders([]);
        }
    }, [history_print_open]);

    const displayedOrder = selectedHistoryOrder || (orders.length > 0 ? orders[0] : null);

    const closeHistoryPrint = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (history_print_open) dispatch(toggleHistoryPrint(undefined));
    };

    const onClickReprint = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        reprintTicket();
    };

    const onClickReprintQuittance = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        reprintQuittance();
    };

    const onClickCancel = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            // cancel order in API
            await dispatch(cancelOrder()).unwrap();
            // Remove order from indexedDb
            storage.removeSelectedOrder(selectedHistoryOrder);
            // Remove order from last printed tickets
            removeTicketFromPrinterMemory();
            if (history_print_open) dispatch(toggleHistoryPrint(undefined));
        } catch (e) {
            console.log(e);
            window.alert("Une erreur s'est produite lors de l'annulation de la commande.");
        }
    };

    if (history_print_open) {
        return (
            <>
                <div className="overlay-dark" onClick={closeHistoryPrint} />
                <div className="resume-wrapper bg-gray">
                    <div className="d-flex justify-content-between">
                        <div>{t('order.total')} :</div>
                        <div>{formatCurrency(displayedOrder?.total || 0, 'CHF')}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>{t('order.cashed')} :</div>
                        <div>{formatCurrency(displayedOrder?.cash || 0, 'CHF')}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>{t('order.cashed_back')} :</div>
                        <div>{formatCurrency(displayedOrder?.change || 0, 'CHF')}</div>
                    </div>
                    <div className="keyboard-actions mt-3">
                        <button
                            className="btn btn-info"
                            onClick={onClickReprintQuittance}
                            style={{ fontSize: '1.5rem', flex: 1 }}
                            disabled={!printer_is_connected || !displayedOrder}
                        >
                            <i className="fas fa-print" /> {t('order.print_receipt')}
                        </button>
                        <button
                            className="btn btn-dark btn-cancel"
                            onClick={onClickReprint}
                            disabled={printer_is_disabled || !printer_is_connected || !displayedOrder}
                        >
                            <i className="fas fa-print" /> {t('order.print_again')}
                        </button>
                    </div>
                    <div className="keyboard-actions mt-3">
                        <button
                            className="btn btn-danger btn-cancel"
                            onClick={onClickCancel}
                            disabled={!displayedOrder}
                        >
                            <i className="fas fa-ban" /> {t('order.cancel')}
                        </button>
                        <button className="btn btn-success btn-continue" onClick={closeHistoryPrint}>
                            <i className="fas fa-check" /> {t('msc.close')}
                        </button>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default HistoryPrint;
