import { MAX_CHARACTERS_PER_LINE } from 'utils/Printer';

// Printer specific helper methods
export const aligneLeftAndRight = (txtLeft: string, txtRight: string): string => {
    if (txtLeft.length > MAX_CHARACTERS_PER_LINE / 2) {
        txtLeft = txtLeft.substring(0, 32) + '...';
    }

    const numberOfWhiteSpace = MAX_CHARACTERS_PER_LINE - txtLeft.length - txtRight.length;

    let str = txtLeft;
    let i = 0;
    while (i < numberOfWhiteSpace) {
        str = str + ' ';
        i++;
    }

    return str + txtRight;
};

export const printLabelAndDots = (label: string): string => {
    let str = label + ': ';

    const numberOfDots = MAX_CHARACTERS_PER_LINE - str.length;

    let i = 0;
    while (i < numberOfDots) {
        str = str + '.';
        i++;
    }

    return str;
};

// "14" => "014"
export const processRentingNumber = (str: string | null): string => {
    // should not happen, but just in case
    if (str === null) {
        return 'XXX';
    }

    while (str.length < 3) {
        str = '0' + str;
    }

    if (str.length > 3) {
        str = str.slice(-3);
    }

    return str;
};
