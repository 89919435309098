import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BrowserStorage from 'utils/BrowserStorage';
import { useAppDispatch, useAppSelector } from 'store';
import { updateIp, updateIsDisabled, updatePort } from 'store/printerSlice';
import { toggleAppConf, setAdminUnlocked } from 'store/appSlice';
import { postAuth } from 'store/apiSlice';

import GeneralSettings from 'components/Settings/GeneralSettings';
import Login from 'components/Settings/Login';
import PrinterSettings from 'components/Settings/PrinterSettings';

interface IProps {
    readonly connectToPrinter: () => void;
}

const browserStorage = new BrowserStorage();

const ApplicationSettings: React.FC<IProps> = ({ connectToPrinter }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [printer_is_connecting, setPrinter_is_connecting] = useState(false);
    const [login_input_changed, setLogin_input_changed] = useState(false);
    const [username, setEvent_username] = useState('');
    const [password, setEvent_password] = useState('');

    const {
        app_conf_open,
        app_is_locked,
        printer_is_disabled,
        printer_is_connected,
        printer_error,
        printer_ip_address,
        printer_port,
        is_posting_auth,
        posting_auth_error,
        admin_unlocked,
    } = useAppSelector((state) => ({
        app_conf_open: state.application.app_conf_open,
        app_is_locked: state.application.app_is_locked,
        printer_is_disabled: state.printer.is_disabled,
        printer_is_connected: state.printer.is_connected,
        printer_error: state.printer.error,
        printer_ip_address: state.printer.ip_address,
        printer_port: state.printer.port,
        is_posting_auth: state.api.is_posting_auth,
        posting_auth_error: state.api.posting_auth_error,
        admin_unlocked: state.application.admin_unlocked,
    }));

    useEffect(() => {
        if (printer_is_connected && !printer_error) {
            setPrinter_is_connecting(false);
        }
    }, [printer_is_connected, printer_error]);

    const closeAppConf = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Only toggle keyboard if it's already open
        if (app_conf_open) dispatch(toggleAppConf());
    };

    const loginInputOnChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        if (e.target.name === 'username') {
            setEvent_username(e.target.value);
        }
        if (e.target.name === 'password') {
            setEvent_password(e.target.value);
        }

        setLogin_input_changed(true);
    };

    const handleLoginOnClick = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Show alert if field is empty
        if (!username || !password) {
            return window.alert('Please fill username and password !');
        }

        // Connect to API
        try {
            await dispatch(postAuth({ username, password })).unwrap();
            // Hide modal
            dispatch(toggleAppConf());
            // Set admin_unlocked
            dispatch(setAdminUnlocked());
            // TODO: multiple accounts
        } catch (e: any) {
            // eslint-disable-next-line
            if (e.message === 'The user credentials were incorrect.') {
                window.alert('Mauvais identifiants');
            } else {
                window.alert("Erreur lors de l'authentification");
            }
        }

        setLogin_input_changed(false);
    };

    const handleLoginStatus = () => {
        const isConnected = !!browserStorage.getAccessToken();
        if (is_posting_auth) return <span className="text-info">{t('status.connecting')}</span>;
        if (posting_auth_error)
            return <span className="text-danger">{posting_auth_error.error ? posting_auth_error.error : 'Error'}</span>;
        if (isConnected) return <span className="text-success">{t('status.connected')}</span>;
        if (!isConnected) return <span className="text-warning">{t('status.disconnected')}</span>;
    };

    const printerInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'address_ip') dispatch(updateIp(e.target.value));
        if (e.target.name === 'port') dispatch(updatePort(e.target.value));
    };

    const handlePrinterOnClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Show an alert if empty
        if (!printer_ip_address || !printer_port) return window.alert('Please fill printer ip address and port !');

        setPrinter_is_connecting(true);

        // Save printer settings in localStorage
        browserStorage.setPrinterIp(printer_ip_address);
        browserStorage.setPrinterPort(parseInt(printer_port));

        // Then connect to printer
        connectToPrinter();
    };

    const printerSwitchOnChange = (checked: boolean): void => {
        dispatch(updateIsDisabled(checked));
    };

    const handlePrinterStatus = () => {
        return printer_is_connecting ? (
            <span className="text-info">{t('status.connecting')}</span>
        ) : printer_error ? (
            <span className="text-danger">{t('status.error')}</span>
        ) : printer_is_connected ? (
            <span className="text-success">{t('status.connected')}</span>
        ) : (
            <span className="text-warning">{t('status.disconnected')}</span>
        );
    };

    if (app_conf_open) {
        // Admin Login
        if (!admin_unlocked) {
            return (
                <>
                    <div className="overlay-dark" />
                    <div className="app-settings-wrapper bg-gray" style={{ width: '444px' }}>
                        <Login
                            username={username}
                            password={password}
                            onLoginInputChange={loginInputOnChange}
                            onLoginClick={handleLoginOnClick}
                            isLoginInputChanged={login_input_changed}
                            loginStatus={handleLoginStatus}
                        />
                    </div>
                </>
            );
        } else {
            if (!app_is_locked) {
                return (
                    <>
                        <div className="overlay-dark" onClick={closeAppConf} />
                        <div className="app-settings-wrapper bg-gray" style={{ width: '600px' }}>
                            <div className="title">{t('navigation.settings')}</div>
                            <GeneralSettings
                                printerIsDisabled={printer_is_disabled}
                                onPrinterSwitchChange={printerSwitchOnChange}
                            />
                            <div className="d-flex">
                                <PrinterSettings
                                    ipAddress={printer_ip_address}
                                    port={printer_port}
                                    onPrinterInputChange={printerInputOnChange}
                                    onPrinterClick={handlePrinterOnClick}
                                    isPrinterConnecting={printer_is_connecting}
                                    printerStatus={handlePrinterStatus}
                                />
                            </div>
                        </div>
                    </>
                );
            }
        }
    }
    return null;
};

export default ApplicationSettings;
