import React from 'react';
import { useTranslation } from 'react-i18next';
import BasketItem from 'components/BasketItem';
import { useAppSelector } from 'store';

const BasketList: React.FC = () => {
    const { t } = useTranslation();
    const items = useAppSelector((state) => state.basket.items);
    const main_event = useAppSelector((state) => state.application.current_main_event);
    const locale = useAppSelector((state) => state.application.locale);

    return (
        <div className="basket-list-wrapper">
            <h1>
                {main_event?.labels.shop_name
                    ? main_event.labels.shop_name[locale]
                        ? main_event.labels.shop_name[locale]
                        : main_event.labels.shop_name.fr
                    : null}
            </h1>
            <div className="basket-list-header">
                <div className="items">
                    <div className="item-description">{t('order.item.description')}</div>
                    <div className="item-quantity">{t('order.item.qty')}</div>
                    <div className="item-price">{t('order.item.unit_price')}</div>
                    <div className="item-total">{t('order.item.total')}</div>
                    <div className="item-delete" />
                </div>
            </div>
            <div className="basket-list-body">
                {items.map((item, index) => {
                    return <BasketItem key={index} item={item} index={index} />;
                })}
            </div>
        </div>
    );
};

export default BasketList;
