import React from 'react';
import Ticket from 'components/Ticket';
import { useAppDispatch, useAppSelector } from 'store';
import { addTicketToBasket } from 'store/basketSlice';
import Buttons from 'components/GuestsAndMembers/Buttons';
import { TicketPrice } from 'types/event';

const TicketsAvailableList: React.FC = () => {
    const dispatch = useAppDispatch();
    const main_event = useAppSelector((state) => state.application.current_main_event);
    const locale = useAppSelector((state) => state.application.locale);
    const manual_entry = useAppSelector((state) => state.application.manual_entry);
    const manual_ticket_price = useAppSelector((state) => state.basket.manual_ticket_price);

    const addTicket = (ticket: TicketPrice) => {
        // Verify if manual entry is open
        if (!manual_entry) return dispatch(addTicketToBasket(ticket));

        // We are in manual entry and want to get the specified price for the ticket
        // Create a new ticket if the manual price is set
        if (manual_ticket_price !== 0) {
            const newTicket = { ...ticket };
            newTicket.price_cents = manual_ticket_price; // Set the new price in cents
            return dispatch(addTicketToBasket(newTicket));
        }
    };

    return (
        <div className="flex-even bg-gray">
            <div className="container-fluid h-100">
                <div className="tickets-list-wrapper">
                    <div className="section">
                        <div className="sub-section">
                            {main_event?.ticket_prices.map((ticket, i) => (
                                <div className="item" key={i}>
                                    <Ticket ticket={ticket} addTicketToBasket={addTicket} />
                                </div>
                            ))}
                        </div>
                    </div>
                    {
                        // Handle subevents
                        main_event &&
                            main_event.sub_events.map(
                                (event) =>
                                    event.ticket_prices.length > 0 && ( // Only show subevent section if there is a ticket
                                        <div className="section">
                                            <h1 className="mb-3">
                                                {event.labels.shop_name
                                                    ? event.labels.shop_name[locale]
                                                        ? event.labels.shop_name[locale]
                                                        : event.labels.shop_name.fr
                                                    : null}
                                            </h1>
                                            <div className="sub-section">
                                                {event.ticket_prices.map((ticket, i) => (
                                                    <div className="item" key={i}>
                                                        <Ticket ticket={ticket} addTicketToBasket={addTicket} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ),
                            )
                    }
                    {
                        // If the event has guest list or members activated
                        (main_event?.guestlist || main_event?.membership) && (
                            <div className="section" key={main_event?.id}>
                                <h1 className="mb-3">Guests & membres</h1>
                                <div className="sub-section guest-members-sub-section">
                                    <Buttons
                                        guests={main_event ? main_event.guestlist : false}
                                        members={main_event ? main_event.membership : false}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default TicketsAvailableList;
