import React, { useRef } from 'react';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly toggle: (value: boolean) => void;
}

const DiscountSwitchButton: React.FC<IProps> = ({ toggle }) => {
    const buttonPercentRef = useRef<HTMLButtonElement | null>(null);
    const buttonChfRef = useRef<HTMLButtonElement | null>(null);

    const switchDiscountToPercent = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Update UI
        buttonPercentRef.current && buttonPercentRef.current.classList.add('btn-primary');
        buttonPercentRef.current && buttonPercentRef.current.classList.remove('btn-light');
        buttonChfRef.current && buttonChfRef.current.classList.remove('btn-primary');
        buttonChfRef.current && buttonChfRef.current.classList.add('btn-light');

        toggle(true);
    };

    const switchDiscountToChf = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Update UI
        buttonChfRef.current && buttonChfRef.current.classList.add('btn-primary');
        buttonChfRef.current && buttonChfRef.current.classList.remove('btn-light');
        buttonPercentRef.current && buttonPercentRef.current.classList.remove('btn-primary');
        buttonPercentRef.current && buttonPercentRef.current.classList.add('btn-light');

        toggle(false);
    };

    return (
        <div className="keyboard-discount-switch">
            <button
                ref={buttonPercentRef}
                className="btn btn-primary btn-key-end discount-switch-key"
                onMouseDown={unFocusHtmlButton}
                onClick={switchDiscountToPercent}
            >
                %
            </button>
            <button
                ref={buttonChfRef}
                className="btn btn-light btn-key-end discount-switch-key"
                onMouseDown={unFocusHtmlButton}
                onClick={switchDiscountToChf}
            >
                CHF
            </button>
        </div>
    );
};

export default DiscountSwitchButton;
