import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BrowserStorage from 'utils/BrowserStorage';

const browserStorage = new BrowserStorage();

export type PrinterState = {
    readonly device_id?: number;
    readonly is_disabled: boolean;
    readonly ip_address: string;
    readonly port: string;
    readonly is_connected: boolean;
    readonly error: boolean;
    readonly is_printing: boolean;
};

export const initialState: PrinterState = {
    is_disabled: browserStorage.getPrinterDisabled(),
    ip_address: browserStorage.getPrinterIp() || '10.10.0.56', // e.g. => '10.10.0.59'
    port: browserStorage.getPrinterPort() || '8043', // http => '8008' / https => '8043'
    is_connected: false,
    error: false,
    is_printing: false,
};

export const printerSlice = createSlice({
    name: 'printer',
    initialState: initialState,
    reducers: {
        updateDeviceId: (state, action: PayloadAction<number>) => {
            state.device_id = action.payload;
        },
        updateIp: (state, action: PayloadAction<string>) => {
            state.ip_address = action.payload;
        },
        updatePort: (state, action: PayloadAction<string>) => {
            state.port = action.payload;
        },
        updateIsConnected: (state, action: PayloadAction<boolean>) => {
            state.is_connected = action.payload;
        },
        updateIsDisabled: (state, action: PayloadAction<boolean>) => {
            state.is_disabled = action.payload;
        },
        updateError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        updateIsPrinting: (state, action: PayloadAction<boolean>) => {
            state.is_printing = action.payload;
        },
    },
});

export const {
    updateDeviceId,
    updateIp,
    updatePort,
    updateIsConnected,
    updateIsDisabled,
    updateError,
    updateIsPrinting,
} = printerSlice.actions;

export default printerSlice.reducer;
