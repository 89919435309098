import moment from 'moment';
import { sort } from 'fast-sort';
import { Order, OrderItem, OrderTicket } from 'types/order';
import { Event, TicketPrice } from 'types/event';
import { BasketItem } from 'types/basket';
import { BasketState } from 'store/basketSlice';
import { formatCurrency } from 'utils/helper';
import { aligneLeftAndRight, printLabelAndDots, processRentingNumber } from 'utils/printer-helpers';
import Printer from 'utils/Printer';

interface LastTicketPrinted {
    readonly basket: Order;
    readonly event: Event;
}

const MAX_TICKETS_IN_MEMORY = 5;
const ERROR_ORDER_NOT_FOUND = 'Order not found in the last printed tickets.';

export default class PrinterTickets extends Printer {
    private setTextStyle(type: 'bold' | 'regular') {
        switch (type) {
            case 'bold':
                this.printer.addTextStyle(false, false, true, this.printer.COLOR_1);
                break;
            case 'regular':
            default:
                this.printer.addTextStyle(false, false, false, this.printer.COLOR_1);
                break;
        }
    }
    private setTextAlign(type: 'left' | 'right' | 'center') {
        switch (type) {
            case 'center':
                this.printer.addTextAlign(this.printer.ALIGN_CENTER);
                break;
            case 'right':
                this.printer.addTextAlign(this.printer.ALIGN_RIGHT);
                break;
            case 'left':
            default:
                this.printer.addTextAlign(this.printer.ALIGN_LEFT);
                break;
        }
    }
    private setupPrinter() {
        this.printer.addPulse(this.printer.DRAWER_1, this.printer.PULSE_100).addTextLang(this.locale);
    }
    // Save the data in memory, for reprinting tickets later
    private addTicketToPrinterMemory(basket: Order, event: Event): void {
        const newTicket = {
            basket: { ...basket },
            event: { ...event },
        };

        this.lastTicketsPrinted.push(newTicket);

        if (this.lastTicketsPrinted.length > MAX_TICKETS_IN_MEMORY) {
            this.lastTicketsPrinted.shift();
        }
    }

    public removeTicketFromPrinterMemory = (order: BasketState, event: Event): void => {
        const ticketToRemove = this.findInLastTicketsPrinted(order);
        if (ticketToRemove) {
            this.lastTicketsPrinted = this.lastTicketsPrinted.filter((o) => o !== ticketToRemove);
        } else {
            console.error('removeTicketFromPrinterMemory' + ERROR_ORDER_NOT_FOUND);
        }
    };

    public printLastTicket = (): void => {
        const lastTicket = this.getLastPrintedTicket();
        if (lastTicket) this.prepareTickets(lastTicket.basket, lastTicket.event);
    };

    public reprintTicket = (order: BasketState, event: Event): void => {
        const ticketToReprint = this.findInLastTicketsPrinted(order);
        if (ticketToReprint) {
            this.prepareTickets(ticketToReprint.basket, ticketToReprint.event, false);
        } else {
            console.error('reprintTicket' + ERROR_ORDER_NOT_FOUND);
        }
    };

    public reprintQuittance = (order: BasketState, event: Event): void => {
        const ticketToReprint = this.findInLastTicketsPrinted(order);
        if (ticketToReprint) {
            this.printQuittance(ticketToReprint.basket, ticketToReprint.event);
        } else {
            console.error(ERROR_ORDER_NOT_FOUND);
        }
    };

    private getLastPrintedTicket = (): LastTicketPrinted | undefined => {
        return this.lastTicketsPrinted[this.lastTicketsPrinted.length - 1];
    };

    public findInLastTicketsPrinted = (order: BasketState): LastTicketPrinted | undefined => {
        // Find in lastTicketsPrinted the basket that matches the order
        return this.lastTicketsPrinted.find((el) => order.id === el.basket.id);
    };

    private isPrintable = (item: OrderItem) =>
        item.pos_printed && (item.article_type === 'ticket' || item.article_type === 'product');

    private isRentingItem = (item: OrderItem): boolean => item.article_type === 'renting';

    // =====================================================
    // prepareTickets: add orders to printer memory + send to printer
    // =====================================================
    public prepareTickets = (basket: Order, event: Event, addToPrinterMemory: boolean = true) => {
        this.updateIsPrintingOnReduxStore(true);

        if (addToPrinterMemory) {
            this.addTicketToPrinterMemory(basket, event);
        }

        // Qty is used to know how many tickets to print
        this.qty = 0;

        // Get printable tickets quantity
        basket.items.filter(this.isPrintable).forEach((ticket, index) => {
            // Add total of tickets to qty
            this.qty = this.qty + ticket.quantity;
        });

        const noRentingItems = !basket.items.some(this.isRentingItem);
        const noPrintableItems = !basket.items.some(this.isPrintable);

        // If basket has at least one renting item, print a rent ticket
        if (!noRentingItems) {
            this.printRent(basket, event);
        }

        // Loop through printable tickets
        basket.items.filter(this.isPrintable).forEach((item) => {
            if (item.tickets === undefined) {
                this.printArticle(basket.seller_name, item, event);
            } else {
                item.tickets.forEach((ticket, index) => {
                    this.printTicket(basket.seller_name, item, ticket, event, index);
                });
            }
        });

        // If there is nothing to print, update the redux store
        if (noRentingItems && noPrintableItems) {
            this.updateIsPrintingOnReduxStore(false);
        }
    };

    // =====================================================
    // printTicket: print a ticket
    // =====================================================
    private printTicket = (
        seller_name: string,
        item: OrderItem,
        ticket: OrderTicket,
        event: Event,
        index: number,
    ): void => {
        if (this.lock) {
            setTimeout(() => {
                this.printTicket(seller_name, item, ticket, event, index);
            }, 300);
        } else {
            this.lock = true;
            this.setupPrinter();

            // Header
            this.setTextAlign('left');
            this.printer.addText(event.contact_name).addText('\n\n');

            // Add item details
            this.setTextStyle('bold');
            this.printer.addText(item.label).addText('\n\n');

            // Add qr code
            this.setTextAlign('center');
            this.printer
                .addSymbol(`${ticket.code}`, this.printer.SYMBOL_QRCODE_MODEL_2, this.printer.LEVEL_M, 3, 3, 0)
                .addText('\n\n');

            // Add name if ticket is nominative
            if (ticket.nominative) {
                this.setTextStyle('regular');
                this.printer
                    .addText(printLabelAndDots(this.translator('customer.lastname')))
                    .addText('\n\n')
                    .addText(printLabelAndDots(this.translator('customer.firstname')))
                    .addText('\n\n');
            }

            // Add pos_label_additional_footer if available (Validité, etc.)
            if (item.pos_additionnal_footer) {
                this.setTextStyle('bold');
                this.printer.addText(item.pos_additionnal_footer).addText('\n\n');
            }

            // Footer
            this.setTextStyle('regular');
            const dateFormat = this.locale === 'de' ? 'dddd D. MMMM YYYY' : 'dddd D MMMM YYYY';
            this.printer.addText(moment().format(dateFormat)).addText('\n');
            this.printer.addText(seller_name).addText('\n\n');
            // this.printer.addTextDouble(true); // Font size 2x
            this.setTextStyle('regular');
            this.printer
                .addText(event.labels.pos_footer_big[this.locale] || event.labels.pos_footer_big.fr)
                .addText('\n\n');
            this.setTextStyle('regular');
            this.printer
                .addTextDouble(false, false)
                .addText(event.labels.pos_footer[this.locale] || event.labels.pos_footer.fr)
                .addFeedLine(3)
                .addCut();

            // Send ticket to printer
            if (this.ePosDev.isConnected()) {
                this.printer.send();
            }
        }
    };

    // =====================================================
    // Print an article (for articles, with pos_printed true but no tickets)
    // =====================================================
    private printArticle = (seller_name: string, item: OrderItem, event: Event): void => {
        if (this.lock) {
            setTimeout(() => {
                this.printArticle(seller_name, item, event);
            }, 300);
        } else {
            this.lock = true;
            this.setupPrinter();

            // Header
            this.setTextAlign('left');
            this.printer.addText(event.contact_name).addText('\n\n');

            // Add item details
            this.setTextStyle('bold');
            this.printer.addText(item.label).addText('\n\n');

            this.setTextAlign('center');

            // Add pos_label_additional_footer if available (Validité, etc.)
            if (item.pos_additionnal_footer) {
                this.setTextStyle('bold');
                this.printer.addText(item.pos_additionnal_footer).addText('\n\n');
            }

            // Footer
            this.setTextStyle('regular');
            const dateFormat = this.locale === 'de' ? 'dddd D. MMMM YYYY' : 'dddd D MMMM YYYY';
            this.printer.addText(moment().format(dateFormat)).addText('\n');
            this.printer.addText(seller_name).addText('\n\n');
            // this.printer.addTextDouble(true); // Font size 2x
            this.setTextStyle('regular');
            this.printer
                .addText(event.labels.pos_footer_big[this.locale] || event.labels.pos_footer_big.fr)
                .addText('\n\n');
            this.setTextStyle('regular');
            this.printer
                .addTextDouble(false, false)
                .addText(event.labels.pos_footer[this.locale] || event.labels.pos_footer.fr)
                .addFeedLine(3)
                .addCut();

            // Send ticket to printer
            if (this.ePosDev.isConnected()) {
                this.printer.send();
            }
        }
    };

    // =====================================================
    // Location ticket
    // =====================================================
    private printRent = (basket: Order, event: Event, timesCount: number = 0) => {
        if (this.lock) {
            setTimeout(() => {
                this.printRent(basket, event, timesCount);
            }, 300);
        } else {
            this.qty = 0;
            this.lock = true;
            this.updateIsPrintingOnReduxStore(true);
            this.setupPrinter();

            // Header
            this.setTextAlign('left');
            this.printer.addText(event.labels.pos_header?.[this.locale] ?? event.labels.pos_header.fr);
            this.printer.addText('\n\n');

            // Items of type renting
            const rentingItems = basket.items.filter((item) => item.article_type === 'renting');
            rentingItems.forEach((item) => {
                this.printer.addText(`${item.quantity}x ${item.label}`);
                this.printer.addText('\n');
            });
            this.printer.addText('\n\n');

            // Location number
            this.setTextStyle('bold');
            this.setTextAlign('center');
            this.printer.addTextSize(5, 5);
            this.printer.addText(processRentingNumber(basket.location_number));

            // Current date
            this.printer.addTextSize(1, 1);
            this.setTextAlign('left');
            this.printer.addText('\n\n');
            this.setTextAlign('center');
            if (this.locale === 'de') {
                this.printer.addText(moment().format('dddd D. MMMM YYYY HH:mm'));
            } else {
                this.printer.addText(moment().format('dddd D MMMM YYYY HH:mm'));
            }

            this.printer.addText('\n');
            this.printer.addText(basket.seller_name);
            this.printer.addText('\n\n');

            // TicketsManager footer
            this.setTextStyle('regular');
            this.setTextAlign('center');
            this.printer.addTextDouble(false, false);
            this.printer.addText(event.labels.pos_footer?.[this.locale] ?? event.labels.pos_footer.fr);

            this.printer.addFeedLine(3);
            this.printer.addCut();

            // Send ticket to printer
            if (this.ePosDev.isConnected()) {
                this.printer.send();

                if (timesCount < 1) {
                    this.lock = false;
                    // Print a second time
                    this.printRent(basket, event, timesCount + 1);
                }
            }
        }
    };

    // =====================================================
    // Quittance
    // =====================================================
    public printQuittance = (basket: Order, event: Event): void => {
        // Verify if the printer is already in use and wait until the printer will be ready
        if (this.lock) {
            setTimeout(() => {
                this.printQuittance(basket, event);
            }, 300);
        } else {
            this.qty = 0;
            this.lock = true;
            this.updateIsPrintingOnReduxStore(true);
            this.setupPrinter();

            // Set Header
            this.setTextAlign('left');
            this.printer.addText(event.labels.pos_header?.[this.locale] ?? event.labels.pos_header.fr);

            this.printer.addText('\n\n');

            // Tickets
            basket.items.forEach((ticket, index) => {
                this.printer.addText(
                    aligneLeftAndRight(
                        `${ticket.quantity}x ${ticket.label}`,
                        formatCurrency(ticket.amount, event.currency),
                    ),
                );
                this.printer.addText('\n\n');
            });

            // Total
            this.printer.addText(
                aligneLeftAndRight(
                    this.translator('order.sub-total'),
                    formatCurrency(basket.sub_total, event.currency),
                ),
            );
            this.printer.addText('\n');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.discount'), formatCurrency(basket.discount, event.currency)),
            );
            this.printer.addText('\n');
            this.setTextStyle('bold');
            this.printer.addText(
                aligneLeftAndRight(
                    this.translator('order.total').toUpperCase(),
                    formatCurrency(basket.total, event.currency),
                ),
            );
            this.printer.addText('\n\n');

            // TAXES
            this.setTextStyle('regular');
            this.printer.addText(this.translator('order.tva', { tax_rate: parseFloat(event.tax_rate) * 100 }));
            this.printer.addText('\n\n');

            // Information
            this.printer.addText(this.translator('order.ticket_as_receipt'));
            // Order id
            if (basket.id) {
                this.printer.addText('\n');
                this.printer.addText(this.translator('order.order_number') + ': ');
                this.printer.addText(basket.id);
                this.printer.addText('\n\n');
            } else {
                this.printer.addText('\n\n');
            }

            // Current date
            this.setTextAlign('center');
            if (this.locale === 'de') {
                this.printer.addText(moment().format('dddd D. MMMM YYYY'));
            } else {
                this.printer.addText(moment().format('dddd D MMMM YYYY'));
            }
            this.printer.addText('\n');
            this.printer.addText(basket.seller_name);
            this.printer.addText('\n\n');

            // Greetings
            //this.printer.addTextDouble(true); // Font size 2x
            this.setTextStyle('bold');
            this.setTextAlign('center');
            this.printer.addText(
                event.labels.pos_footer_big[this.locale]
                    ? event.labels.pos_footer_big[this.locale]
                    : event.labels.pos_footer_big.fr,
            );
            this.printer.addText('\n\n');

            // TicketsManager footer
            this.setTextStyle('regular');
            this.setTextAlign('center');
            this.printer.addTextDouble(false, false);
            this.printer.addText(event.labels.pos_footer?.[this.locale] ?? event.labels.pos_footer.fr);

            this.printer.addFeedLine(3);
            this.printer.addCut();

            // Send ticket to printer
            if (this.ePosDev.isConnected()) {
                this.printer.send();
            }
        }
    };

    // =====================================================
    // Décompte du jour
    // =====================================================
    public printDayCount = (orders: BasketState[], event: Event): void => {
        this.updateIsPrintingOnReduxStore(true);
        this.setupPrinter();

        // Header
        this.setTextAlign('left');
        this.printer.addText(event.labels.pos_header?.[this.locale] ?? event.labels.pos_header.fr);
        this.printer.addText('\n\n');

        // Date
        this.setTextStyle('bold');
        if (this.locale === 'de') {
            this.printer.addText(this.translator('order.countdown', { date: moment().format('dddd D. MMMM YYYY') }));
        } else {
            this.printer.addText(this.translator('order.countdown', { date: moment().format('dddd D MMMM YYYY') }));
        }
        this.printer.addText('\n\n');

        // Get all tickets which was payed by cash only
        const now = moment();
        let total_cash = 0;
        let total_card = 0;
        let sub_total = 0;
        let discount_cash = 0;
        let discount_card = 0;
        let all_tickets_cash: TicketPrice[] = [];
        let all_tickets_cash_grouped: BasketItem[] = [];
        let all_tickets_card: TicketPrice[] = [];
        let all_tickets_card_grouped: BasketItem[] = [];
        orders.forEach((order) => {
            if (!order.card && now.isSame(moment.unix(parseInt(order.date as string)), 'd')) {
                all_tickets_cash = [...all_tickets_cash, ...order.raw_items];
                total_cash = total_cash + order.total;
                sub_total = sub_total + order.sub_total;
                discount_cash = discount_cash + order.discount;
            }
        });

        // Group tickets by id and price
        all_tickets_cash.forEach((item) => {
            const isPresent = all_tickets_cash_grouped.findIndex(
                (element) => element.id === item.id && element.price_unit === item.price_cents,
            );

            if (isPresent !== -1) {
                all_tickets_cash_grouped[isPresent].amount =
                    all_tickets_cash_grouped[isPresent].amount + item.price_cents;
                all_tickets_cash_grouped[isPresent].quantity = all_tickets_cash_grouped[isPresent].quantity + 1;
            } else {
                all_tickets_cash_grouped.push({
                    id: item.id,
                    sku: item.shop_sku,
                    amount: item.price_cents,
                    price_unit: item.price_cents,
                    quantity: 1,
                    label: item.labels.pos_label
                        ? item.labels.pos_label[this.locale]
                            ? item.labels.pos_label[this.locale]
                            : item.labels.pos_label.fr
                        : item.labels.label[this.locale]
                        ? item.labels.label[this.locale]
                        : item.labels.label.fr,
                    org_item: item,
                    // Add sorting value from ticket price or from order in the list
                    sorting: item.sorting ?? event.ticket_prices.findIndex((t) => t.shop_sku === item.shop_sku),
                });
            }
        });

        orders.forEach((order) => {
            if (order.card && now.isSame(moment.unix(parseInt(order.date as string)), 'd')) {
                all_tickets_card = [...all_tickets_card, ...order.raw_items];
                total_card = total_card + order.total;
                discount_card = discount_card + order.discount;
            }
        });

        // Group tickets by id and price
        all_tickets_card.forEach((item) => {
            const isPresent = all_tickets_card_grouped.findIndex(
                (element) => element.id === item.id && element.price_unit === item.price_cents,
            );

            if (isPresent !== -1) {
                all_tickets_card_grouped[isPresent].amount =
                    all_tickets_card_grouped[isPresent].amount + item.price_cents;
                all_tickets_card_grouped[isPresent].quantity = all_tickets_card_grouped[isPresent].quantity + 1;
            } else {
                all_tickets_card_grouped.push({
                    id: item.id,
                    sku: item.shop_sku,
                    amount: item.price_cents,
                    price_unit: item.price_cents,
                    quantity: 1,
                    label: item.labels.pos_label
                        ? item.labels.pos_label[this.locale]
                            ? item.labels.pos_label[this.locale]
                            : item.labels.pos_label.fr
                        : item.labels.label[this.locale]
                        ? item.labels.label[this.locale]
                        : item.labels.label.fr,
                    org_item: item,
                    sorting: item.sorting
                        ? item.sorting
                        : event.ticket_prices.findIndex((t) => t.shop_sku === item.shop_sku), // Add sorting value from ticket price or from order in the list
                });
            }
        });

        // Cash tickets
        if (all_tickets_cash_grouped.length > 0) {
            // Seller and cash or card
            this.setTextStyle('bold');
            this.printer.addText(
                aligneLeftAndRight(
                    `${this.storage.getPOSSeller()?.id.toString() || '1'} - ${
                        this.storage.getPOSSeller()?.name || 'Caisse'
                    }`,
                    this.translator('order.payment_cash'),
                ),
            );
            this.printer.addText('\n\n');

            // Tickets
            this.setTextStyle('regular');
            sort(all_tickets_cash_grouped)
                .asc([(t) => t.sorting])
                .forEach((ticket) => {
                    // Add label
                    this.printer.addText(
                        aligneLeftAndRight(
                            `${ticket.quantity}x ${ticket.label}`,
                            formatCurrency(ticket.amount, event.currency),
                        ),
                    );
                    this.printer.addText('\n');
                });

            this.printer.addText('\n');

            // Sub-total & discount
            this.setTextStyle('regular');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.sub-total'), formatCurrency(sub_total, event.currency)),
            );
            this.printer.addText('\n');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.discount'), formatCurrency(discount_cash, event.currency)),
            );
            this.printer.addText('\n\n');

            // Total
            this.setTextStyle('bold');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.total'), formatCurrency(total_cash, event.currency)),
            );
            this.printer.addText('\n\n');
        }

        if (all_tickets_cash_grouped.length > 0 && all_tickets_card_grouped.length > 0) {
            // Separator
            this.setTextStyle('regular');
            this.printer.addText('================================================');
            this.printer.addText('\n\n');
        }

        // Card tickets
        if (all_tickets_card_grouped.length > 0) {
            // Seller and cash or card
            this.setTextStyle('bold');
            this.printer.addText(
                aligneLeftAndRight(
                    `${this.storage.getPOSSeller()?.id.toString() || '1'} - ${
                        this.storage.getPOSSeller()?.name || 'Caisse'
                    }`,
                    this.translator('order.payment_card'),
                ),
            );
            this.printer.addText('\n\n');

            // Tickets
            this.setTextStyle('regular');
            sort(all_tickets_card_grouped)
                .asc([(t) => t.sorting])
                .forEach((ticket) => {
                    // Add label
                    console.debug('Print ticket', ticket);
                    this.printer.addText(
                        aligneLeftAndRight(
                            `${ticket.quantity}x ${ticket.label}`,
                            formatCurrency(ticket.amount, event.currency),
                        ),
                    );
                    this.printer.addText('\n');
                });

            this.printer.addText('\n');

            // Sub-total & discount
            this.setTextStyle('regular');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.discount'), formatCurrency(discount_card, event.currency)),
            );
            this.printer.addText('\n\n');

            // Total
            this.setTextStyle('bold');
            this.printer.addText(
                aligneLeftAndRight(this.translator('order.total'), formatCurrency(total_card, event.currency)),
            );
        }

        this.printer.addFeedLine(3);
        this.printer.addCut();

        // Send ticket to printer
        if (this.ePosDev.isConnected()) {
            this.printer.send();
        }
    };
}
