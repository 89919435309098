import React, { useState } from 'react';
import { Customer } from 'types/customer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleGuestAndMembersValidate, toggleGuestsAndMembers } from 'store/appSlice';
import { unFocusHtmlButton } from 'utils/helper';
import { postGuest } from 'store/apiSlice';
import ActionsToolbar from 'components/ActionsToolbar';

interface IProps {
    readonly customer: Customer | null;
}

const ValidateModal: React.FC<IProps> = ({ customer }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const is_posting_guest = useAppSelector((state) => state.api.is_posting_guest);
    const [numberOfGuest, setNumberOfGuest] = useState(1);

    const onClickOverlay = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(toggleGuestAndMembersValidate());
    };

    const increase = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setNumberOfGuest(numberOfGuest + 1);
    };

    const decrease = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setNumberOfGuest(numberOfGuest - 1);
    };

    const onPost = async (e: React.MouseEvent | React.TouchEvent): Promise<void> => {
        e.preventDefault();
        e.stopPropagation();

        try {
            if (customer) {
                await dispatch(postGuest({ uuid: customer.uuid, type: customer.type, number: numberOfGuest })).unwrap();
            }

            dispatch(toggleGuestAndMembersValidate());
            dispatch(toggleGuestsAndMembers(false));
        } catch (e: any) {
            console.error(e);
            window.alert("Une erreur s'est produite durant l'envoi des données guest.");
        }
    };

    const cancelAction = (e: React.MouseEvent | React.TouchEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(toggleGuestAndMembersValidate());
    };

    return (
        <>
            <div className="overlay-dark" onClick={onClickOverlay} />
            <div className="guests-and-members-validate-wrapper bg-gray d-flex flex-column">
                <div className="title">
                    {customer?.lastname} {customer?.firstname}
                </div>
                <div className="bg-light mb-2 flex-grow-1 p-3 text-center">
                    <div>{customer?.group_name}</div>
                    <div>{t(`customer.${customer?.type}`)}</div>
                    <div>
                        {t('customer.entry_available')} 1 {t('customer.entry')} + {customer && customer.quota - 1}{' '}
                        {t('customer.companions')}
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            {t('customer.number_of_entries')} {customer && customer.quota_used + numberOfGuest}/
                            {customer?.quota}
                        </div>
                        <div>
                            <button
                                className="btn btn-dark btn-circle mr-2"
                                onMouseDown={unFocusHtmlButton}
                                disabled={numberOfGuest === 1 || customer?.quota === 1}
                                onClick={decrease}
                            >
                                <i className="fas fa-minus" />
                            </button>
                            <button
                                className="btn btn-dark btn-circle ml-2"
                                onMouseDown={unFocusHtmlButton}
                                disabled={
                                    (customer && customer.quota_used + numberOfGuest === customer?.quota) ||
                                    customer?.quota === 1
                                }
                                onClick={increase}
                            >
                                <i className="fas fa-plus" />
                            </button>
                        </div>
                    </div>
                </div>
                <ActionsToolbar
                    cancel_action={cancelAction}
                    continue_action={onPost}
                    continue_text={t('msc.confirm')}
                    size={72}
                    continue_disabled={is_posting_guest}
                    radius={false}
                />
            </div>
        </>
    );
};

export default ValidateModal;
