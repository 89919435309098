import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import logo from 'assets/logo.svg';
import { openUserActions, toggleAppConf, toggleChooseEvent, toggleHistoryList } from 'store/appSlice';

interface NavLinkProps {
    className?: string;
    icon: string;
    text: string;
    onClick: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ icon, text, onClick, className }) => (
    <button className={`nav-link ${className}`} onClick={onClick}>
        <i className={icon} /> {text}
    </button>
);

const Navbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const printer_connected = useAppSelector((state) => state.printer.is_connected);
    const admin_unlocked = useAppSelector((state) => state.application.admin_unlocked);
    const app_admin_mode = useAppSelector((state) => state.application.app_admin_mode);
    const pos_sellers_mode = useAppSelector((state) => state.application.pos_sellers_mode);
    const pos_seller = useAppSelector((state) => state.application.pos_seller);
    const app_is_locked = useAppSelector((state) => state.application.app_is_locked);
    const account = useAppSelector((state) => state.application.account);

    return (
        <nav className="navbar navbar-dark bg-dark">
            <img className="brand-logo" src={logo} alt="Logo" />
            {!admin_unlocked && (
                <ul className="navbar-nav flex-row-reverse">
                    <li className="nav-link d-flex align-items-center">
                        <button
                            className="nav-link text-white bg-primary px-4 py-2 rounded"
                            onClick={() => dispatch(toggleAppConf())}
                        >
                            <i className={'fa-regular fa-circle-user mr-2'} />
                            <span>{t('navigation.unlock')}</span>
                        </button>
                    </li>
                </ul>
            )}
            {admin_unlocked && (
                <ul className="navbar-nav flex-row">
                    {app_is_locked && <i className={'fas fa-hashtag text-danger mr-1'} />}
                    {app_admin_mode &&
                        account?.active_main_events?.length &&
                        account.active_main_events.length > 1 &&
                        !app_is_locked && (
                            <li className="nav-item mr-4">
                                <NavLink
                                    icon="fa-solid fa-list"
                                    text=""
                                    onClick={() => dispatch(toggleChooseEvent())}
                                />
                            </li>
                        )}
                    {!app_is_locked && (
                        <li className="nav-item mr-3">
                            <NavLink
                                icon={cx('fas fa-print', !printer_connected ? 'text-danger' : 'text-success')}
                                text={t('navigation.printer')}
                                onClick={() => dispatch(toggleAppConf())}
                            />
                        </li>
                    )}
                    {!app_is_locked && (
                        <li className="nav-item">
                            <NavLink
                                icon="fa-solid fa-history"
                                text={t('navigation.transactions')}
                                onClick={() => dispatch(toggleHistoryList())}
                            />
                        </li>
                    )}
                    {app_admin_mode && !pos_sellers_mode && (
                        <li className="nav-link d-flex align-items-center">
                            <button
                                className="nav-link text-white bg-primary px-4 py-2 rounded"
                                onClick={() => dispatch(openUserActions())}
                            >
                                <i className={'fa-regular fa-circle-user mr-2'} />
                                <span>{account?.labels.label.fr}</span>
                                <i className={'fa-solid fa-arrow-right-from-bracket ml-4'} />
                            </button>
                        </li>
                    )}
                    {/* POS User logged in */}
                    {pos_sellers_mode && pos_seller !== null && (
                        <button
                            className={cx(
                                'nav-link text-white  px-4 py-2 rounded',
                                app_is_locked ? 'bg-secondary' : 'bg-primary',
                            )}
                            onClick={() => dispatch(openUserActions())}
                        >
                            {app_admin_mode && (
                                <>
                                    <i className={'fa-regular fa-circle-user mr-2 text-warning'} />
                                    <span className="text-warning mr-2">{account?.labels.label.fr}</span>
                                </>
                            )}
                            <span>
                                {app_is_locked && 'Connecté: '}
                                {pos_seller.name}
                            </span>
                            {!app_is_locked && <i className={'fa-solid fa-arrow-right-from-bracket ml-4'} />}
                        </button>
                    )}
                </ul>
            )}
        </nav>
    );
};

export default Navbar;
