module.exports = {
    languageTag: 'fr-CH',
    delimiters: {
        thousands: "'",
        decimal: '.',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    ordinal: (number) => {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '.-',
        position: 'postfix',
        code: 'CHF',
    },
    currencyFormat: {
        thousandSeparated: true,
        totalLength: 4,
        spaceSeparated: true,
        average: true,
    },
    formats: {
        fourDigits: {
            totalLength: 4,
            spaceSeparated: true,
            average: true,
        },
        fullWithTwoDecimals: {
            output: 'currency',
            mantissa: 2,
            spaceSeparated: true,
            thousandSeparated: true,
        },
        fullWithTwoDecimalsNoCurrency: {
            mantissa: 2,
            thousandSeparated: true,
        },
        fullWithNoDecimals: {
            output: 'currency',
            spaceSeparated: true,
            thousandSeparated: true,
            mantissa: 0,
        },
    },
};
