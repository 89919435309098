import React, { useRef } from 'react';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly toggle: (value: boolean) => void;
}

const MethodSwitchButton: React.FC<IProps> = ({ toggle }) => {
    const buttonCacheRef = useRef<HTMLButtonElement | null>(null);
    const buttonCardRef = useRef<HTMLButtonElement | null>(null);

    const switchDiscountToCache = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Update UI
        buttonCacheRef.current && buttonCacheRef.current.classList.add('btn-primary');
        buttonCacheRef.current && buttonCacheRef.current.classList.remove('btn-light');
        buttonCardRef.current && buttonCardRef.current.classList.remove('btn-primary');
        buttonCardRef.current && buttonCardRef.current.classList.add('btn-light');

        toggle(false);
    };

    const switchDiscountToCredit = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Update UI
        buttonCardRef.current && buttonCardRef.current.classList.add('btn-primary');
        buttonCardRef.current && buttonCardRef.current.classList.remove('btn-light');
        buttonCacheRef.current && buttonCacheRef.current.classList.remove('btn-primary');
        buttonCacheRef.current && buttonCacheRef.current.classList.add('btn-light');

        toggle(true);
    };

    return (
        <div className="keyboard-payment-switch">
            <button
                ref={buttonCacheRef}
                className="btn btn-primary btn-key-end payment-switch-key"
                onMouseDown={unFocusHtmlButton}
                onClick={switchDiscountToCache}
            >
                <i className="fas fa-dollar-sign" />
            </button>
            <button
                ref={buttonCardRef}
                className="btn btn-light btn-key-end payment-switch-key"
                onMouseDown={unFocusHtmlButton}
                onClick={switchDiscountToCredit}
            >
                <i className="fas fa-credit-card" />
            </button>
        </div>
    );
};

export default MethodSwitchButton;
