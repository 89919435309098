import React from 'react';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly updateInput: (value: string) => void;
    readonly input: string;
    readonly decimal?: boolean;
    readonly disabled?: boolean;
}

const Keyboard: React.FC<IProps> = ({ updateInput, input, decimal = true, disabled = false }) => {
    const clicked = (e: React.MouseEvent | React.TouchEvent, key: string) => {
        e.preventDefault();
        e.stopPropagation();
        // _ is DELETE last character
        if (key === '_') return updateInput(input.slice(0, -1));

        updateInput(input + key);
    };

    const renderButton = (value: string, position: 'start' | 'middle' | 'end', isDisabled?: boolean) => (
        <button
            className={`btn btn-light btn-key-${position}`}
            onClick={(event) => clicked(event, value)}
            onMouseDown={unFocusHtmlButton}
            disabled={isDisabled || disabled}
        >
            {value === '_' ? <i className="fas fa-backspace" /> : value}
        </button>
    );

    const positions: ('start' | 'middle' | 'end')[] = ['start', 'middle', 'end'];

    const rows = [
        { values: ['1', '2', '3'], className: 'keyboard-keypad-row-start' },
        { values: ['4', '5', '6'], className: 'keyboard-keypad-row-middle' },
        { values: ['7', '8', '9'], className: 'keyboard-keypad-row-middle' },
        { values: [decimal ? '.' : '', '0', '_'], className: 'keyboard-keypad-row-end' },
    ];

    return (
        <div className="keyboard-keypad">
            {rows.map((row, rowIndex) => (
                <div key={rowIndex} className={row.className}>
                    {row.values.map((value, index) =>
                        React.cloneElement(renderButton(value, positions[index], value === '.' && !decimal), {
                            key: `${rowIndex}-${index}`,
                        }),
                    )}
                </div>
            ))}
        </div>
    );
};

export default Keyboard;
