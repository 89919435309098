import React from 'react';

const DATABASE_NAME = 'TicketsManager2_POS';
const TABLE_ORDER = 'order';

class IndexedDB {
    constructor() {
        if (!('indexedDB' in window)) {
            window.alert("This browser doesn't support IndexedDB.");
            return;
        }

        const idb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        const request = idb.open(DATABASE_NAME, 1);

        request.onerror = this.handleDatabaseError;
        request.onsuccess = this.handleDatabaseSuccess;
        request.onupgradeneeded = this.handleDatabaseUpgrade;
    }

    handleDatabaseError = (event) => {
        console.error('Database error: ' + event.target.errorCode);
    };

    handleDatabaseSuccess = (event) => {
        console.debug('IndexDB open success');
        this.db = event.target.result;
    };

    handleDatabaseUpgrade = (event) => {
        console.debug('IndexDB update success');
        this.db = event.target.result;

        if (!this.db.objectStoreNames.contains(TABLE_ORDER)) {
            this.db.createObjectStore(TABLE_ORDER, { autoIncrement: true });
        }
    };

    addOrder = (data) => {
        const request = this.getObjectStore('readwrite').add(data);
        request.onsuccess = () => console.debug('The order has been written successfully');
        request.onerror = this.handleRequestError('The order could not be saved');
    };

    getOrderById = (id) => {
        const request = this.getObjectStore().get(id);
        request.onerror = this.handleRequestError(`Get order ${id} failed`);
        request.onsuccess = this.handleGetOrderSuccess;
    };

    getAllOrders = (callback) => {
        const result = [];
        const objectStore = this.getObjectStore();
        objectStore.openCursor().onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
                result.push({ id: cursor.key, ...cursor.value });
                cursor.continue();
            } else {
                result.sort((a, b) => b.date - a.date);
                callback(result);
            }
        };
    };

    updateOrderById = (id, data) => {
        const request = this.getObjectStore('readwrite').put({ id, data });
        request.onsuccess = () => console.debug('Order updated successfully');
        request.onerror = this.handleRequestError('Order could not be updated');
    };

    getOrderKeyByDataId = (dataId) => {
        return new Promise((resolve, reject) => {
            const objectStore = this.getObjectStore();
            // Open a cursor to iterate over records
            const request = objectStore.openCursor();
            request.onsuccess = (event) => {
                const cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.id === dataId) {
                        resolve(cursor.key);
                    } else {
                        cursor.continue();
                    }
                } else {
                    resolve(null);
                }
            };
            request.onerror = (event) => {
                reject('Error in fetching data from IndexedDB: ' + event.target.errorCode);
            };
        });
    };
    removeSelectedOrder = (data) => {
        this.getOrderKeyByDataId(data.id)
            .then((key) => {
                if (key !== null) {
                    this.getObjectStore('readwrite').delete(key);
                    console.log(`IndexDB key ${key} deleted.`);
                } else {
                    console.error(`IndexDB key ${key} not found, cannot delete it.`);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    clearTableOrder = () => {
        const request = this.getObjectStore('readwrite').clear();
        request.onsuccess = () => console.debug('Order table cleared successfully');
        request.onerror = this.handleRequestError('Order table could not be cleared');
    };

    getObjectStore(mode = 'readonly') {
        return this.db.transaction([TABLE_ORDER], mode).objectStore(TABLE_ORDER);
    }

    handleRequestError = (message) => (event) => {
        console.debug(`${message}. Error: ${event.target.errorCode}`);
    };

    handleGetOrderSuccess = (event) => {
        const request = event.target;
        if (request.result) {
            console.debug(request.result);
        } else {
            console.debug('No data record');
        }
    };
}

// Create a React context for IndexDB
const IndexedDBContext = React.createContext(new IndexedDB());

export default IndexedDBContext;
