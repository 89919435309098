import React from 'react';
import { shortFormatCurrency, shortenLabel } from 'utils/helper';
import { useAppSelector } from 'store';
import { TicketPrice } from 'types/event';

interface IProps {
    readonly ticket: TicketPrice;
    readonly addTicketToBasket: (ticket: TicketPrice) => void;
}

const Ticket: React.FC<IProps> = ({ ticket, addTicketToBasket }) => {
    const locale = useAppSelector((state) => state.application.locale);

    const clicked = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Add ticket to the basket
        addTicketToBasket(ticket);
    };

    return (
        <div className="card card-ticket" onClick={clicked}>
            <div className="card-body d-flex flex-column justify-content-between">
                {ticket.labels.label[locale] ? (
                    <strong>{shortenLabel(ticket.labels.label[locale])}</strong>
                ) : (
                    <strong>{shortenLabel(ticket.labels.label.fr)}</strong>
                )}
                <small className="d-flex justify-content-between align-items-center">
                    {shortFormatCurrency(ticket.price_cents, 'CHF')}
                    {ticket.article_type === 'renting' && <i className="fas fa-arrow-right-arrow-left" />}
                </small>
            </div>
        </div>
    );
};

export default Ticket;
