import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import watch from 'redux-watch';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store';
import AppContainer from 'AppContainer';
import BrowserStorage from 'utils/BrowserStorage';

import * as serviceWorker from 'serviceWorkerRegistration';

import 'assets/scss/App.scss';

/** i18n translations **/
import common_fr from 'i18n/fr/common.json';
import common_de from 'i18n/de/common.json';

i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    // debug: process.env.NODE_ENV !== 'production',
    resources: {
        fr: { common: common_fr },
        de: { common: common_de },
    },
    lng: navigator.language.substring(0, 2),
    fallbackLng: 'fr',
    ns: ['common'],
    defaultNS: 'common',
});
// .then(() => console.debug('Translator loaded...'));

const storage = new BrowserStorage();

let wPrinterIsDisabled = watch(store.getState, 'printer.is_disabled');
store.subscribe(
    wPrinterIsDisabled((newVal, oldVal, objectPath) => {
        storage.setPrinterDisabled(newVal);
    }),
);

const renderApp = (el: HTMLDivElement) => {
    const root = ReactDOM.createRoot(el);
    root.render(
        <Provider store={store}>
            <AppContainer />
        </Provider>,
    );
};

(() => {
    const el = document.getElementById('root') as HTMLDivElement | null;
    if (el !== null) renderApp(el);
})();

serviceWorker.register();
