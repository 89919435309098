import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Keyboard from 'components/Keyboard';
import MethodSwitchButton from 'components/Payment/MethodSwitchButton';
import CashChoiceButton from 'components/Payment/CashChoiceButton';
import ActionsToolbar from 'components/ActionsToolbar';
import { formatCurrency } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleVirtualKeyboard } from 'store/appSlice';
import { updateCash, updateCreditCard, updateBasketAfterApiResponse } from 'store/basketSlice';
import { updateIsPrinting } from 'store/printerSlice';
import { postBasket } from 'store/apiSlice';

const VirtualKeyboard: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const payment_virtual_keyboard_open = useAppSelector((state) => state.application.payment_virtual_keyboard_open);
    const basket = useAppSelector((state) => state.basket);
    const printer_is_connected = useAppSelector((state) => state.printer.is_connected);
    const printer_is_disabled = useAppSelector((state) => state.printer.is_disabled);
    const is_posting_basket = useAppSelector((state) => state.api.is_posting_basket);
    const [card, setCard] = useState(false);
    const [input, setInput] = useState('');
    const [change, setChange] = useState(0);

    useEffect(() => {
        if (payment_virtual_keyboard_open) {
            setInput('');
            setCard(false);
            setChange(0);
        }
    }, [payment_virtual_keyboard_open]);

    // Update change when input change
    useEffect(() => {
        if (input === '') {
            setChange(0);
        } else {
            setChange(parseFloat(input) * 100 - basket.total);
        }
    }, [input]);

    const toggleKeyboard = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // Only toggle keyboard if it's already open
        if (payment_virtual_keyboard_open) dispatch(toggleVirtualKeyboard());
    };

    const toggleMethodSwitchButton = (value: boolean) => {
        setCard(value);

        // If credit card selected set the amount of the bill
        if (value) setInput((basket.total / 100).toString());
    };

    const onCancelAction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        dispatch(toggleVirtualKeyboard());
        setInput('');
    };

    const onContinueAction = async (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();

        // Set credit card, cash and change to basket
        dispatch(updateCash(input !== '' ? parseFloat(input) * 100 : 0));
        dispatch(updateCreditCard(card));

        try {
            const resp = await dispatch(postBasket()).unwrap();
            // update basket based on API response
            dispatch(updateBasketAfterApiResponse(resp));
            dispatch(toggleVirtualKeyboard());
        } catch (e) {
            dispatch(updateIsPrinting(false));
            window.alert("Une erreur s'est produite durant l'envoi des données.");
            console.error(e);
        }
    };

    const checkInputError = () => {
        return basket.total > (input ? parseFloat(input) : 0) * 100;
    };

    const clearInput = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        setInput('');
    };

    const addCash = (value: string) => {
        const current = parseFloat(input ? input : '0');
        const total = current + parseFloat(value);
        setInput(total.toString());
    };

    if (payment_virtual_keyboard_open) {
        return (
            <>
                <div className="overlay-dark" onClick={toggleKeyboard} />
                <div className="keyboard-wrapper-payment bg-gray">
                    <div className="text-to-pay">
                        {t('order.to_pay', { amount: formatCurrency(basket.total, 'CHF') })}
                    </div>
                    <div className="keyboard-input">
                        <div>{t('order.cashed')} :</div>
                        <div className="d-flex">
                            {!card && (
                                <div className="delete-icon" onClick={clearInput}>
                                    <i className="far fa-times-circle" />
                                </div>
                            )}
                            <div className={cx(checkInputError() ? 'text-danger' : '')}>{input ? input : '0.00'}</div>
                        </div>
                    </div>
                    <div className="keyboard-payment">
                        <CashChoiceButton setCash={addCash} disabled={card} />
                        <Keyboard updateInput={setInput} input={input} disabled={card} />
                        <MethodSwitchButton toggle={toggleMethodSwitchButton} />
                    </div>
                    <div className="text-change">
                        <div>{t('order.cash_back')} :</div>
                        <div className={cx(checkInputError() ? 'text-danger' : '')}>
                            {formatCurrency(change, 'CHF')}
                        </div>
                    </div>
                    <ActionsToolbar
                        size={64}
                        radius={false}
                        icon={printer_is_disabled ? 'check' : 'print'}
                        continue_text={printer_is_disabled ? t('msc.cash_in') : t('msc.print')}
                        cancel_action={onCancelAction}
                        continue_action={onContinueAction}
                        continue_disabled={
                            checkInputError() || (!printer_is_disabled && !printer_is_connected) || is_posting_basket
                        }
                    />
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default VirtualKeyboard;
