import BrowserStorage from 'utils/BrowserStorage';
import moment from 'moment';
import { JwtPayload } from 'types/api';

export const HOST: string = process.env.REACT_APP_API_HOST as string;
export const CLIENT_ID: string = process.env.REACT_APP_API_CLIENT_ID as string;
export const CLIENT_SECRET: string = process.env.REACT_APP_API_CLIENT_SECRET as string;
export const REQUEST_TIMEOUT = 60000; // 1min

const browserStorage = new BrowserStorage();
export let accessToken = browserStorage.getAccessToken() || '';
export let refreshToken = browserStorage.getRefreshToken() || '';

export const setAccessToken = (token: string) => {
    accessToken = token;
    browserStorage.setAccessToken(token);
};

export const setRefreshToken = (token: string) => {
    refreshToken = token;
    browserStorage.setRefreshToken(token);
};

export const isTokenExpired = (token: string): boolean => {
    if (!token || token === '') return true;
    const jwtPayload = parseJwtPayload(token);
    if (!jwtPayload) return true;
    return moment.unix(Math.trunc(jwtPayload.exp)).diff(moment(), 'minute') <= 5;
};

export const parseJwtPayload = (token: string): JwtPayload | null => {
    try {
        const decodedString = window.atob(token.split('.')[1]);
        const payload = JSON.parse(decodedString);
        return payload as JwtPayload;
        // depricated:
        // return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        console.warn('Error to read JWT token. \n', e);
        return null;
    }
};

export const ensureTokenIsFresh = async () => {
    if (isTokenExpired(accessToken)) {
        const resp = await updateAccessTokenFromRefreshToken();
        if (!resp) {
            return Promise.reject(resp);
        }
    }
};

export const updateAccessTokenFromRefreshToken = async (): Promise<boolean> => {
    const params = new URLSearchParams();
    params.append('client_id', CLIENT_ID);
    params.append('client_secret', CLIENT_SECRET);
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);

    try {
        const response = await fetch(`${HOST}/api/v2/oauth/token`, {
            method: 'POST',
            body: params,
            signal: AbortSignal.timeout(REQUEST_TIMEOUT),
            headers: { Accept: 'application/json' },
        });

        if (!response.ok) return Promise.reject(response);

        const jsonResponse = await response.json();

        browserStorage.setAccessToken(jsonResponse.access_token);
        browserStorage.setRefreshToken(jsonResponse.refresh_token);

        accessToken = jsonResponse.access_token;
        refreshToken = jsonResponse.refresh_token;

        return true;
    } catch (e) {
        return false;
    }
};
