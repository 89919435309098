import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import applicationReducer from 'store/appSlice';
import apiReducer from 'store/apiSlice';
import printerReducer from 'store/printerSlice';
import basketReducer from 'store/basketSlice';

const store = configureStore({
    reducer: {
        application: applicationReducer,
        api: apiReducer,
        printer: printerReducer,
        basket: basketReducer,
    },
    preloadedState: window.preloadedState || {},
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
