import React from 'react';
import { useAppSelector } from 'store';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly onChange: (value: string) => void;
    readonly activeFilters: string;
}

const Filter: React.FC<IProps> = ({ onChange, activeFilters }) => {
    const customers = useAppSelector((state) => state.api.customers);
    const guests_and_members_open = useAppSelector((state) => state.application.guests_and_members_open);

    const onClick = (e: React.MouseEvent | React.TouchEvent, filter: string): void => {
        e.preventDefault();
        e.stopPropagation();

        onChange(filter);
    };

    return (
        <>
            <div className="filters mb-1">
                {filters.slice(0, 13).map((filter) => (
                    <button
                        key={filter}
                        className="btn btn-light filter-btn text-uppercase"
                        onMouseDown={unFocusHtmlButton}
                        onClick={(e) => onClick(e, filter)}
                        disabled={
                            customers
                                .filter(
                                    (c) =>
                                        c.type.includes(guests_and_members_open as string) &&
                                        c.lastname.toLowerCase().startsWith(activeFilters),
                                )
                                .filter((c) => c.lastname.toLowerCase().charAt(activeFilters.length) === filter)
                                .length === 0
                        }
                    >
                        {filter}
                    </button>
                ))}
            </div>
            <div className="filters">
                {filters.slice(13, 26).map((filter) => (
                    <button
                        key={filter}
                        className="btn btn-light filter-btn text-uppercase"
                        onMouseDown={unFocusHtmlButton}
                        onClick={(e) => onClick(e, filter)}
                        disabled={
                            customers
                                .filter(
                                    (c) =>
                                        c.type.includes(guests_and_members_open as string) &&
                                        c.lastname.toLowerCase().startsWith(activeFilters),
                                )
                                .filter((c) => c.lastname.toLowerCase().charAt(activeFilters.length) === filter)
                                .length === 0
                        }
                    >
                        {filter}
                    </button>
                ))}
            </div>
        </>
    );
};

const filters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
];

export default Filter;
