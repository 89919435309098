import React, { useEffect, useState, useRef, useContext } from 'react';
import { FZ_MASTER_PWD } from 'utils/helper';
import { useAppDispatch, useAppSelector } from 'store';
import {
    setLocked,
    setAdminMode,
    setPOSSeller,
    closeAppLogin,
    updateCurrentMainEvent,
    toggleChooseEvent,
    triggerCreateNewSession,
} from 'store/appSlice';
import { updateSeller } from 'store/basketSlice';
import Keyboard from 'components/Keyboard';
import { useTranslation } from 'react-i18next';
import BrowserStorage from 'utils/BrowserStorage';
import IndexedDBContext from 'utils/IndexedDB';

const browserStorage = new BrowserStorage();

const LockScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [pin, setPin] = useState('');
    const {
        pos_sellers_mode,
        user_actions_open,
        app_is_locked,
        app_login_open,
        app_admin_mode,
        account,
        current_main_event,
        session_uuid,
    } = useAppSelector((state) => state.application);
    const indexedDB = useContext(IndexedDBContext);

    const pinInputRef = useRef<HTMLDivElement | null>(null);
    const [showConfirm, setshowConfirm] = useState(false);

    const updatePinInput = (pwd: string) => {
        let maskedPin = '';

        for (let i = 0; i < pwd.length; i++) {
            maskedPin = maskedPin + '&#11044';
        }

        if (pinInputRef.current) pinInputRef.current.innerHTML = maskedPin;
    };

    const acc = browserStorage.getAccount();
    const adminPins = [FZ_MASTER_PWD].concat(acc ? [acc.s_pin.toString()] : []);
    const pos_sellers = acc ? acc.pos_sellers : [];
    const pos_seller = browserStorage.getPOSSeller();

    useEffect(() => {
        updatePinInput(pin);

        switch (pos_sellers_mode) {
            // POS_MODE TRUE
            case true:
                switch (pos_seller) {
                    // FIRST TIME LOGIN: don't allow admin_mode, check for pins in pos_sellers
                    case null:
                        const matchingSeller = pos_sellers.find((seller) => seller.pin.toString() === pin);
                        if (matchingSeller) {
                            dispatch(setAdminMode(false));
                            dispatch(setLocked(false));
                            dispatch(setPOSSeller(matchingSeller));
                            browserStorage.setPOSSeller(matchingSeller);
                            dispatch(updateSeller(matchingSeller));
                            dispatch(closeAppLogin());
                            handleEventChoice();
                            setPin('');
                        } else if (pin.length === FZ_MASTER_PWD.length) {
                            handleWrongPin();
                        }
                        break;
                    // ALREADY LOGGED IN: allow admin_mode, check for pos_seller pin
                    default:
                        // SUPER PIN MODE
                        if (pin && adminPins.includes(pin)) {
                            dispatch(setAdminMode(true));
                            dispatch(closeAppLogin());
                            setPin('');
                        }
                        // POS SELLER PIN
                        else if (pin === pos_seller.pin.toString()) {
                            dispatch(setAdminMode(false));
                            dispatch(setLocked(false));
                            dispatch(closeAppLogin());
                            setPin('');
                        } else if (pin.length === FZ_MASTER_PWD.length) {
                            handleWrongPin();
                        }
                        break;
                }
                break;

            // POS_MODE FALSE
            case false:
                if (pin && adminPins.includes(pin)) {
                    handleRegularLogin();
                } else if (pin.length === FZ_MASTER_PWD.length) {
                    handleWrongPin();
                }
                break;
        }
    }, [pin]);

    const handleRegularLogin = () => {
        dispatch(setAdminMode(true));
        dispatch(setLocked(false));
        dispatch(closeAppLogin());
        setPin('');
        if (!current_main_event) {
            handleEventChoice();
        }
    };

    const handleEventChoice = () => {
        if (account) {
            if (account.active_main_events.length === 1) {
                dispatch(updateCurrentMainEvent(account.active_main_events[0]));
                dispatch(triggerCreateNewSession());
            } else {
                dispatch(toggleChooseEvent());
            }
        }
    };

    const handleWrongPin = () => {
        if (pinInputRef.current) pinInputRef.current.classList.add('text-danger');

        setTimeout(() => {
            setPin('');
            if (pinInputRef.current) pinInputRef.current.classList.remove('text-danger');
        }, 500);
    };

    const isLockScreenShown = () => {
        // User actions are open and app is locked
        const isLockedAndUserActionsOpenPOS = app_is_locked && user_actions_open && !app_admin_mode;
        const isLockedAndUserActionsOpenRegular =
            app_is_locked && user_actions_open && app_admin_mode && !pos_sellers_mode;
        const isLockedWithAccountButNoEvent = app_is_locked && account && !current_main_event;
        return (
            isLockedAndUserActionsOpenPOS ||
            isLockedAndUserActionsOpenRegular ||
            app_login_open ||
            isLockedWithAccountButNoEvent
        );
    };

    const handleAppReset = () => {
        browserStorage.clear();
        indexedDB.clearTableOrder();
        setTimeout(() => {
            window.location.reload();
            console.clear();
        }, 300);
    };

    const toggleConfirmModal = () => {
        setshowConfirm(!showConfirm);
    };

    return (
        <>
            {isLockScreenShown() ? (
                <>
                    <div className="overlay-dark" />
                    <div className="app-settings-wrapper bg-gray" key="login" style={{ width: '444px' }}>
                        <div className="title">{t('settings.pin_code')}</div>
                        <div className="input" ref={pinInputRef} />
                        <Keyboard updateInput={setPin} input={pin} />
                        <div className="bottom-info-right">Mode: {pos_sellers_mode ? 'POS seller' : 'Regular'}</div>
                    </div>
                    {!session_uuid && (
                        <button className="btn btn-warning appResetBtn" onClick={toggleConfirmModal}>
                            Se déconnecter
                        </button>
                    )}
                    {showConfirm && !session_uuid && (
                        <div className="confirm-app-reset bg-gray text-center">
                            <p className="">
                                {t('msc.reset-app-text-start')}
                                <strong>{account?.labels.label.fr}</strong>
                                {t('msc.reset-app-text-end')}
                            </p>
                            <div className="actions">
                                <button className="btn btn-danger" onClick={toggleConfirmModal}>
                                    <i className="far fa-times-circle mr-2"></i>
                                    {t('msc.cancel')}
                                </button>
                                <button className="btn btn-success" onClick={handleAppReset}>
                                    <i className="fas fa-check mr-2" />
                                    {t('msc.confirm')}
                                </button>
                            </div>
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
};

export default LockScreen;
