import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'types/customer';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleGuestAndMembersValidate } from 'store/appSlice';
import ValidateModal from 'components/GuestsAndMembers/ValidateModal';
import { unFocusHtmlButton } from 'utils/helper';

interface IProps {
    readonly customers: Customer[];
}

const List: React.FC<IProps> = ({ customers }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const guests_and_members_validate_open = useAppSelector(
        (state) => state.application.guests_and_members_validate_open,
    );
    const [selected, setSelected] = useState<Customer | null>(null);

    const onClick = (e: React.MouseEvent | React.TouchEvent, customer: Customer): void => {
        e.preventDefault();
        e.stopPropagation();

        setSelected(customer);
        dispatch(toggleGuestAndMembersValidate());
    };

    return (
        <>
            <div className="customers-wrapper flex-grow-1 d-flex flex-column">
                <div className=" customers-table-header">
                    <div className="container-fluid">
                        <div className="row row-cols-2 text-center">
                            <div>
                                <strong>{t('customer.name')}</strong>
                            </div>
                            <div>
                                <strong>{t('customer.entries')}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 position-relative overflow-auto">
                    <div className="customers-list container-fluid position-absolute">
                        {customers.map((customer) => (
                            <div className="row row-cols-2 mb-2 align-items-center" key={customer.uuid}>
                                <button
                                    className="btn btn-light filter-btn"
                                    disabled={customer.quota_used >= customer.quota}
                                    onClick={(e) => onClick(e, customer)}
                                    onMouseDown={unFocusHtmlButton}
                                >
                                    {customer.lastname} {customer.firstname}
                                </button>
                                <div className="text-center">
                                    {customer.quota_used} {t('customer.quota_used')} / {customer.quota}{' '}
                                    {t('customer.quota_available')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {guests_and_members_validate_open && <ValidateModal customer={selected} />}
        </>
    );
};

export default List;
