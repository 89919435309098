import moment from 'moment';
import { MainEvent } from 'types/event';
import { Account, PosSeller } from 'types/account';

/**
 * Manage offline data
 **/

// Define ALL localStorage Keys using an enum
enum StorageKeys {
    APP_LAST_USE = 'app_last_use',
    ACCOUNT = 'account',
    ADMIN_UNLOCKED = 'admin_unlocked',
    POSSELLER = 'pos_seller',
    API_KEY = 'api_key',
    PRINTER_IP_ADDRESS = 'printer_ip',
    PRINTER_PORT = 'printer_port',
    PRINTER_DISABLED = 'printer_disabled',
    CURRENT_MAIN_EVENT = 'main_event',
    API_REFRESH_TOKEN = 'refresh_token',
    API_ACCESS_TOKEN = 'access_token',
    POSSELLER_MODE = 'pos_seller_mode',
    SESSION_UUID = 'session_uuid',
}

class BrowserStorage {
    private localStorage: Storage = window.localStorage;
    private sessionStorage: Storage = window.sessionStorage;

    constructor() {
        this.set(StorageKeys.APP_LAST_USE, moment().format('X'));
    }

    private set<T>(key: StorageKeys, value: T, useSession?: boolean): void {
        const storage = useSession ? this.sessionStorage : this.localStorage;
        if (typeof value === 'boolean' || typeof value === 'number') {
            storage.setItem(key, value.toString());
        } else {
            storage.setItem(key, JSON.stringify(value));
        }
    }

    private get<T>(key: StorageKeys, useSession?: boolean): T | null {
        const storage = useSession ? this.sessionStorage : this.localStorage;
        const item = storage.getItem(key);
        if (item === null) return null;
        try {
            return JSON.parse(item);
        } catch {
            return item as unknown as T;
        }
    }

    public getAccessToken = (): string | null => this.get(StorageKeys.API_ACCESS_TOKEN, true);
    public setAccessToken = (token: string): void => this.set(StorageKeys.API_ACCESS_TOKEN, token, true);

    public getRefreshToken = (): string | null => this.get(StorageKeys.API_REFRESH_TOKEN);
    public setRefreshToken = (token: string): void => this.set(StorageKeys.API_REFRESH_TOKEN, token);

    public getCurrentMainEvent = (): MainEvent | null => this.get(StorageKeys.CURRENT_MAIN_EVENT);
    public setCurrentMainEvent = (event: MainEvent): void => this.set(StorageKeys.CURRENT_MAIN_EVENT, event);

    public getAccount = (): Account | null => this.get(StorageKeys.ACCOUNT);
    public setAccount = (acc: Account): void => this.set(StorageKeys.ACCOUNT, acc);

    public getAdminUnlocked = (): boolean => this.get(StorageKeys.ADMIN_UNLOCKED) === true;
    public setAdminUnlocked = (unlocked: boolean): void => this.set(StorageKeys.ADMIN_UNLOCKED, unlocked);

    public getPOSSeller = (): PosSeller | null => this.get(StorageKeys.POSSELLER);
    public setPOSSeller = (posSeller: PosSeller | null): void => this.set(StorageKeys.POSSELLER, posSeller);

    public getPOSSellerMode = (): boolean => this.get(StorageKeys.POSSELLER_MODE) === true;
    public setPOSSellerMode = (pos_seller_mode: boolean): void => this.set(StorageKeys.POSSELLER_MODE, pos_seller_mode);

    public getSessionUUID = (): string | null => this.get(StorageKeys.SESSION_UUID);
    public setSessionUUID = (session_uuid: string): void => this.set(StorageKeys.SESSION_UUID, session_uuid);

    public getPrinterDisabled = (): boolean => this.get(StorageKeys.PRINTER_DISABLED) === true;
    public setPrinterDisabled = (disabled: boolean): void => this.set(StorageKeys.PRINTER_DISABLED, disabled);

    public getPrinterIp = (): string | null => this.get(StorageKeys.PRINTER_IP_ADDRESS);
    public setPrinterIp = (ip: string): void => this.set(StorageKeys.PRINTER_IP_ADDRESS, ip);

    public getPrinterPort = (): string | null => this.get(StorageKeys.PRINTER_PORT);
    public setPrinterPort = (port: number): void => this.set(StorageKeys.PRINTER_PORT, port);

    public clear = (): void => {
        this.localStorage.clear();
        this.sessionStorage.clear();
        this.set(StorageKeys.APP_LAST_USE, moment().format('X'));
    };

    public clearUser = (): void => {
        this.set(StorageKeys.APP_LAST_USE, moment().format('X'));
        // Delete the specified keys
        this.localStorage.removeItem(StorageKeys.POSSELLER);
        this.localStorage.removeItem(StorageKeys.SESSION_UUID);
        this.localStorage.removeItem(StorageKeys.CURRENT_MAIN_EVENT);

        // clear them in sessionStorage as well
        this.sessionStorage.removeItem(StorageKeys.POSSELLER);
        this.sessionStorage.removeItem(StorageKeys.SESSION_UUID);
        this.sessionStorage.removeItem(StorageKeys.CURRENT_MAIN_EVENT);
    };
}

export default BrowserStorage;
